<template>
    <div>
      <b-navbar
        toggleable="md" 
        type=""
        style="padding-top: 0; margin-top: 0; min-height:4em;background-color: white;  box-shadow: 0px 1px 5px 0px rgba(158,158,158,0.3);"
      >
      
        <validacionSession></validacionSession>
        <div v-if="esPlan == true" class="d-flex justify-content-between align-items-center w-100">
          <b-navbar-brand href="#">
            <img width="100" src="../../assets/logo_dentalia.png" />
          </b-navbar-brand>
          <b-navbar-nav >
            <b-nav-item to="/inicio" :active="$route.path === '/inicio'"><span class="text-navegacion">Inicio</span></b-nav-item>
            <b-nav-item to="/planes" :active="$route.path === '/planes'" class="fondo-nav"><span class="text-navegacion">Planes dentales</span></b-nav-item>
              <b-nav-item to="/carrito" :active="$route.path === '/carrito'" href="#">
                <b-icon-cart class="text-navegacion"></b-icon-cart>
                <span class="text-navegacion">({{ cantidadCarrito }})</span>
              </b-nav-item>
              
            </b-navbar-nav>
          <div class="d-flex align-items-center" >
            
            <img v-if="logo != logoVacio" width="90" :src="logo" />
            
            <b-nav-item-dropdown
              id="my-nav-dropdown"
              text=""
              toggle-class="nav-link-custom text-navegacion"
              right
            >
              <b-dropdown-item v-on:click="logout()">Cerrar sesión</b-dropdown-item>
            </b-nav-item-dropdown>
          </div>
          </div>

          <div v-else class="d-flex justify-content-center align-items-center w-100">
            <b-navbar-brand href="#">
              <img width="100" src="../../assets/logo_dentalia.png" />
            </b-navbar-brand>
          </div>
      
      </b-navbar>
    </div>
  </template>
  
  <script>
    import ValidacionSession from "@/components/control-acceso/ValidacionSession";
    export default {
      name: "NavBar",
      components: { ValidacionSession },
      props: {
        mostrar: {
          require: true,
          type: Boolean,
        },
        membresias: {
          type: Boolean,
          default: false
        }
      },
      data: () => ({
        carritoPlanes: null,
        logo: null,
        logoVacio: "data:image/png;base64,",
        cantidadCarrito: 0,
        showSubMenu: false,
      }),
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push("/");
    }
  },
  created() {
    this.esPlan = this.$session.get('esPlan')
    this.logo = this.convertirImagen(this.$localStorageService.getLogo());

    let style = document.documentElement.style;
    this.$localStorageService.getColores().forEach((colores) => {
      style.setProperty("--" + colores.elemento, colores.color);
    });
    this.carrito();
  },
  computed: {
    rutaActual() {
      return this.$route.path;
    },
  },
  mounted() {
    this.$root.$on("carritonav", () => {
      this.carrito();
    });

    document.addEventListener("click", this.handleDocumentClick);
  },
  beforeDestroy() {

    document.removeEventListener("click", this.handleDocumentClick);
  },
  methods: {
    activateOption(option) {
      this.activeOption = option;
    },
    handleDocumentClick(event) {

      const dropdownContainer = this.$el.querySelector(".dropdown-container");
      if (
        dropdownContainer &&
        !dropdownContainer.contains(event.target) &&
        this.showSubMenu
      ) {
        this.showSubMenu = false;
      }
    },
    toggleSubMenu() {
      this.showSubMenu = !this.showSubMenu;
    },
    logout: function () {
      this.terminarControlAcceso();
      localStorage.clear();
      this.$session.destroy();
      this.$router.push("/");
    },
    carrito() {
      this.carritoPlanes = this.$session.get("carrito");
      if (this.carritoPlanes.length > 0) {
        this.cantidadCarrito = this.carritoPlanes
          .map((plan) => plan.cantidad)
          .reduce((a, b) => a + b);
      } else {
        this.cantidadCarrito = 0;
      }
    },
    convertirImagen(datos) {
      let binary = "";
      let bytes = new Uint8Array(datos);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return "data:image/png;base64," + btoa(binary);
    },
    terminarControlAcceso() {
      let parametros = {
        idControlAcceso: this.$session.get("idControlAcceso"),
      };
      const url = this.$apiControlAcceso + "cerrarSession/token";
      this.$http
        .post(url, parametros)
        .then((response) => {
          if (response.data.data) {
            console.log("termino con acceso de control");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>

@media (max-width: 768px) {
  .navbar-expand-md .navbar-toggler-icon {
    display: none; 
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row; 
  }

  .b-dropdown {
    position: static !important; 
  }

  .nav-link-custom {
    padding: 0.5rem 0.75rem; 
  }
}


</style>
