<template>
  <div></div>
</template>

<script>
export default {
  name: "LoginTokenTema",
  data: () => ({
    mensajeError: "Usuario o contraseña incorrectos",
  }),
  created() {
    this.verificarTokenAccesoPortal();
  },
  methods: {
    verificarTokenAccesoPortal() {
      const url = this.$baseBack + "/token";
      const headers = {
        "refresh-token": this.$route.params.token,
      }
      this.$http
        .post(url,{},  {headers})
        .then((response) => {
          this.$session.set("token", response.headers.authorization);
        })
          .then(() => {
            this.$http.post(this.$baseBack + '/me').then( response => {
              this.$session.start();
              this.$localStorageService.asignarUsuarioAutenticado(response.data.data);
            }).then(()=> {
              if (this.$localStorageService.getError()) {
                this.mostrarMensajeError(
                    this.$localStorageService.getTipoError()
                );
              } else {
                this.$router.push({ name: "tema" });
              }
            })
          })
        .catch((err) => {
          this.mostrarMensajeError(this.mensajeError);
          console.log(err);
        });
    },
    mostrarMensajeError(mensaje) {
      this.$swal({
        icon: "error",
        title: `${mensaje}`,
        confirmButtonText: "OK",
      });
    },
  },
};
</script>
