<template>
  <div class="fullscreen-bg">
    <div class="overlay"></div>
    <img src="@/assets/imagenes_planes/img2.jpg" alt="Imagen de fondo" class="background-image" />


    <div class="">
      <c_login class="overlay-text login"/> 
      <pie class="fadeIn fifth" :login="true" />
    </div>
  </div>
</template>

<script>
    import pie from "@/components/Footer.vue";
   import c_login from "@/components/C_Login.vue";
export default {
  name: "FullScreenBackground",
  components: {
    pie,
      c_login,
    },
};
</script>

<style scoped>


.fullscreen-bg {
  position: relative;
  width: 100%;
  height: 100vh; 
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-left: 6%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #8dc7e85f 
}

.overlay-text {
  position: absolute;
  top: 0%;
  left: 0%;
}


@media screen and (min-width: 768px) and (max-width: 1000px) {
  .fullscreen-bg {
  position: relative;
  width: 100%;
  height: 100vh; 
  overflow: hidden;
}
  .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-left: 0%;
}

.overlay {
  position: absolute;

  width: 100%;
  height: 100%;
  background-color: #8dc7e85f 
}

.overlay-text {
  position: absolute;
  top: 10%;
  left: 10%;
  justify-content: center;
 align-items: center;
}

}

</style>
