<template>
  <div class="fullscreen-bg ">
    <BarraNavegacion />
       <ImgInicio />
    <div >
      <div class="row d-flex justify-content-center cardInicio">
        <div class="col-sm-12 col-md-6 col-lg-3  tarjeta" style="background-color: #76c893">
          <em class="ahorro_svg"></em>
          <p class="" style="font-weight: bold">Ahorro económico</p>
          <p class="text-left">
            Nuestro planes dentales te brindarán descuentos exclusivos en
            tratamientos y servicios, ahorrándote dinero en tus visitas dentales.
          </p>
        </div>
        <div
          class="col-sm-12 col-md-6 col-lg-3 tarjeta"
          style="background-color: #52b69a"
        >
          <em class="diente_svg"></em>
          <p class="" style="font-weight: bold">Atención integral</p>
          <p class="text-left">
            Tendrás acceso a una amplia gama de servicios dentales, desde
            limpiezas y empastes hasta tratamientos especializados, todo en un
            solo lugar.
          </p>
        </div>
        <div
          class="col-sm-12 col-md-6 col-lg-3 tarjeta"
          style="background-color: #34a0a4"
        >
          <em class="protecion_svg"></em>
          <p class="text-center" style="font-weight: bold">Cuidado preventivo</p>
          <p class="text-left">
            Incluye exámenes regulares, limpiezas y radiografías, lo que te ayuda
            a prevenir problemas dentales y mantener una sonrisa saludable.
          </p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 tarjeta" style="background-color: #168aad">
          <em class="dentista_svg"></em>
          <p class="" style="font-weight: bold">Atención personalizada</p>
          <p class="text-left">
            Nuestro equipo dental altamente capacitado te brinda una atención
            cálida y personalizada, escuchando tus necesidades y brindándote un
            plan de tratamiento individualizado.
          </p>
        </div>
      </div>
    </div>
    

<!--     <pie class="fadeIn fifth" :login="true" /> -->
  </div>
</template>

<script>
/* import pie from "@/components/Footer.vue"; */
import BarraNavegacion from "../../views/nuevo_portal/BarraNavegacion";
import ImgInicio from "../../components/nuevo_portal/ImgInicio"
export default {
  name: "PlanesInformacion",
  components: {
/*     pie, */
    BarraNavegacion,
    ImgInicio
  },
  created() {
    this.$session.set("esPlan", true)
  }
};
</script>

<style scoped>
 
.fullscreen-bg {
  height: 100%;
  background: linear-gradient(to bottom, #FFF, #F0F9FF);
}

.cardInicio {
  margin-left: 1%;
  margin-top: -10vh;
  width: 100%;
}
.tarjeta {
  margin: 2%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2%;
  min-height: 19em;
  max-height: 20em;
  max-width: 19em;
  border-radius: 15px;
  font-size: 0.9em;
  color: white;
  box-shadow: 2px 3px 4px #0000005d;
} 

.ahorro_svg {
  content: url("../../assets/icons/svg-ahorro.svg");
}
.diente_svg {
  content: url("../../assets/icons/svg-diente.svg");
}
.protecion_svg {
  content: url("../../assets/icons/svg-protecion.svg");
}
.dentista_svg {
  content: url("../../assets/icons/svg-dentista.svg");
}
.texto-tenemos {
  text-align: center;
  color: white;
}
</style>
