<template>
  <div>
    <BarraNavegacion v-if="$session.get('token')" :membresias="membresias"></BarraNavegacion>
    <div class="cardPrincipal" style="background-color: white">
      <p class="text-titulo">
        Facturación para ticket {{ idTicket }}
        <b-icon
          icon="question-circle-fill"
          v-on:click="mostrarCert"
          style="color: #0069a9;"
        >
        </b-icon>
      </p>

      <div class="mt-4">
        <p class="text-subtitulos">
          Datos fiscales
        </p>
        <b-form v-on:submit.prevent="guardar()">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
              <b-form-group>
                <b-form-radio-group
                  @input="listaFiscal"
                  id="tipo"
                  v-model="datoFiscal.tipoPersona"
                  :disabled="guardadoDatosFiscales"
                  name="tipo"
                >
                  <b-form-radio :value="2">Fisica</b-form-radio>
                  <b-form-radio :value="1">Moral</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <b-form-group>
                <label for="rfc">RFC<span style="color: red">*</span> </label>
                <b-input-group class="mb-2 pr-4">
                  <b-form-input
                    id="rfc"
                    name="rfc"
                    placeholder="RFC"
                    v-model="datoFiscal.rfc"
                    @input="datoFiscal.rfc = datoFiscal.rfc.toUpperCase()"
                    maxLength="13"
                    :state="
                      datoFiscal.rfc != null && datoFiscal.rfc.length >= 12
                    "
                    required
                    style="border-radius: 10px !important;"
                    :disabled="guardadoDatosFiscales"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="datoFiscal.rfc != null && datoFiscal.rfc.length >= 12"
                >
                  El campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <b-form-group>
                <label for="regimen">Regimen fiscal</label>
                <b-input-group class="mb-2 pr-4">
                  <b-form-select
                    id="regimen"
                    name="regimen"
                    placeholder="Regimen Fiscal"
                    v-model="datoFiscal.regimenFiscal"
                    :options="regimenFiscales"
                    value-field="idRegimenFiscal"
                    text-field="nombre"
                    :disabled="guardadoDatosFiscales"
                    :state="datoFiscal.regimenFiscal !== null"
                    style="border-radius: 10px !important;"
                    required
                  >
                  </b-form-select>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="datoFiscal.regimenFiscal !== null"
                >
                  El campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-4" v-if="datoFiscal.tipoPersona === 1">
              <b-form-group>
                <label for="nombre">Nombre de la razón social </label>
                <b-input-group size="sm" class="mb-2">
                  <b-form-input
                    v-model="datoFiscal.nombreCompleto"
                    @input="
                      datoFiscal.nombreCompleto =
                        datoFiscal.nombreCompleto.toUpperCase()
                    "
                    :state="
                      datoFiscal.nombreCompleto != null &&
                      datoFiscal.nombreCompleto.length >= 1
                    "
                    minLength="1"
                    :disabled="guardadoDatosFiscales"
                    required
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="
                      datoFiscal.nombreCompleto != null &&
                      datoFiscal.nombreCompleto.length >= 3
                    "
                  >
                    El campo es requerido
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4" v-if="datoFiscal.tipoPersona === 2">
              <b-form-group>
                <label for="nombre"
                  >Nombre(s)<span style="color: red">*</span>
                </label>
                <b-input-group size="sm" class="mb-2">
                  <b-form-input
                    id="nombre"
                    name="rfc"
                    placeholder="Nombre"
                    v-model="datoFiscal.nombre"
                    @input="datoFiscal.nombre = datoFiscal.nombre.toUpperCase()"
                    :state="
                      datoFiscal.nombre != null && datoFiscal.nombre.length >= 1
                    "
                    minLength="1"
                    required
                    :disabled="guardadoDatosFiscales"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="
                    datoFiscal.nombre != null && datoFiscal.nombre.length >= 3
                  "
                >
                  El campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4" v-if="datoFiscal.tipoPersona === 2">
              <b-form-group>
                <label for="paterno"
                  >Apellido paterno<span style="color: red">*</span>
                </label>
                <b-input-group size="sm" class="mb-2">
                  <b-form-input
                    id="paterno"
                    name="paterno"
                    placeholder="Apellido paterno"
                    v-model="datoFiscal.paterno"
                    @input="
                      datoFiscal.paterno = datoFiscal.paterno.toUpperCase()
                    "
                    :state="
                      datoFiscal.paterno != null &&
                      datoFiscal.paterno.length >= 1
                    "
                    minLength="1"
                    required
                    :disabled="guardadoDatosFiscales"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="
                    datoFiscal.paterno != null && datoFiscal.paterno.length >= 3
                  "
                >
                  El campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4" v-if="datoFiscal.tipoPersona === 2">
              <b-form-group>
                <label for="materno">Apellido materno </label>
                <b-input-group size="sm" class="mb-2">
                  <b-form-input
                    id="materno"
                    name="materno"
                    placeholder="Apellido materno"
                    v-model="datoFiscal.materno"
                    @input="
                      datoFiscal.materno = datoFiscal.materno.toUpperCase()
                    "
                    minLength="1"
                    :disabled="guardadoDatosFiscales"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <b-form-group>
                <label for="correo"
                  >Correo electrónico<span style="color: red">*</span>
                </label>
                <b-input-group size="sm" class="mb-2">
                  <b-form-input
                    type="email"
                    id="correo"
                    name="correo"
                    placeholder="Correo"
                    v-model="datoFiscal.correo"
                    :state="validMail(datoFiscal.correo)"
                    :disabled="guardadoDatosFiscales"
                    required
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback :state="validMail(datoFiscal.correo)">
                  El campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <b-form-group>
                <label for="cp"
                  >Código postal<span style="color: red">*</span>
                </label>
                <b-input-group size="sm" class="mb-2">
                  <b-form-input
                    id="cp"
                    name="cp"
                    placeholder="Código postal"
                    type="number"
                    v-model="datoFiscal.cp"
                    :state="datoFiscal.cp != null && datoFiscal.cp.length === 5"
                    maxLength="5"
                    required
                    :disabled="guardadoDatosFiscales"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  :state="datoFiscal.cp != null && datoFiscal.cp.length === 5"
                >
                  El campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
              <b-button
                type="submit"
                variant="outline-primary"
                class="btn-outline-primary-custom"
                :disabled="guardadoDatosFiscales"
                >Confirmar datos</b-button
              >
            </div>
          </div>
        </b-form>
      </div>
      <b-form v-on:submit.prevent="crearFactura">
        <div v-if="guardadoDatosFiscales" class="mt-5 row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <label class="labelsF"
              >RFC<span style="color: red">*</span
              ><b-spinner small class="mr-1" v-if="loaderTicket"></b-spinner
            ></label>
            <b-input-group>
              <b-form-select
                v-model="rfc"
                :options="listaRfcPaciente"
                value-field="rfc"
                text-field="rfc"
                :state="rfc != null"
                required
                @input="obtenerCodigoUsoFactura"
              >
              </b-form-select>
              <b-form-invalid-feedback :state="rfc != null">
                Debe seleccionar una opción
              </b-form-invalid-feedback>
            </b-input-group>
            <label v-if="rfcSeleccionado !== null" style="font-size: 12px"
              >Razón Social:{{ rfcSeleccionado.nombreRfc }}</label
            >
            <br />
            <label v-if="rfcSeleccionado !== null" style="font-size: 12px"
              >Tipo Persona:{{ rfcSeleccionado.tipoRfc }}</label
            >
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
            <label class="labelsF"
              >Uso de CFDI<span style="color: red">*</span
              ><b-spinner small class="mr-1" v-if="loaderCFDI"></b-spinner
            ></label>
            <b-input-group>
              <b-form-select
                v-model="codigoUsoFactura"
                :options="listaCodigoFactura"
                value-field="codigo"
                text-field="descripcion"
                :state="codigoUsoFactura != null"
                required
              >
              </b-form-select>
              <b-form-invalid-feedback :state="codigoUsoFactura != null">
                Debe seleccionar una opción
              </b-form-invalid-feedback>
            </b-input-group>
          </div>
        </div>

        <div class="mt-5 mb-5 d-flex justify-content-center">
          <b-button variant="primary" v-if="enviando" disabled>
            <b-spinner small></b-spinner>
            Cargando...
          </b-button>

          <b-button
            :disabled="!guardadoDatosFiscales"
            type="submit"
            variant="primary"
            v-else
            class="botones"
          >
            Solicitar factura
          </b-button>
        </div>
      </b-form>
    </div>

    <b-modal title="¡Recuerda!" visible>
      <p>
        Para un mejor servicio ahora tienes 30 días naturales a partir de la
        fecha de tu consumo para solicitar tu factura. Recuerda que la fecha de
        la factura corresponde al día que la generas.
      </p>
      <template #modal-footer="{ ok }">
        <b-button variant="outline-primary" @click="ok()"> OK</b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="certificadosModal"
      title="Verifica tu certificado"
      size="xl"
      hide-footer
    >
      <div class="text-center">
        <p>Solo ingresa los dígitos del id de tu ticket</p>
        <img src="../../assets/imgCertificado.png" alt="imgCert" width="900" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import BarraNavegacion from "../../views/nuevo_portal/BarraNavegacion";

export default {
  name: "Facturacion",
  components: {
    BarraNavegacion,
  },
  data: () => ({
    guardadoDatosFiscales: false,
    rfc: null,
    idTicket: null,
    enviando: false,
    codigoUsoFactura: null,
    listaCodigoFactura: [],
    regimenFiscales: [],
    showModal: false,
    datoFiscal: {
      idPaciente: null,
      rfc: null,
      regimenFiscal: null,
      tipoPersona: 2,
      nombre: null,
      nombreCompleto: null,
      paterno: null,
      materno: null,
      cp: null,
      correo: null,
      idTicket: null
    },
    loaderAcciones: false,
    blntieneDatos: false,
    idTitular: null,
    listaRfcPaciente: [],
    idTitular2: null,
    idTicket2: null,
    rfcSeleccionado: null,
    loaderTicket: false,
    loaderCFDI: false,
    certificadosModal: false,
    membresias: false,
  }),
  created() {
    this.idTicket = this.$session.get('idTicket');
    this.datoFiscal.idTicket = this.idTicket;
    this.obtenerRfcTicketPaciente(1);
    this.listaFiscal();
    let carrito = this.$session.get('carrito');
    if(carrito !== null && carrito[0] !== null && carrito[0].membresias){
      this.membresias = true;
    }
  },
  methods: {
    mostrarCert() {
      this.certificadosModal = true;
    },
    crearFactura() {
      this.enviando = true;
      const url = this.$baseBack + "/api/portalV2/crear/factura";
      let parametros = {
        rfc: this.$global.textoMayus(this.rfc),
        idTicket: this.idTicket,
        usoFactura: this.codigoUsoFactura,
        fechaFactura: this.$moment(new Date()).format("YYYY-MM-DD"),
        idTitular: this.idTitular,
        horas: this.$horas(),
      };
      this.$http
        .post(url, parametros)
        .then((response) => {
          if (response.status == 200 && response.data.data.error === false) {
            window.open(response.data.data.ligaFactura, "_blank");
            this.$swal({
              icon: "success",
              title: `${response.data.data.mensaje}`,
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "Planes" });
              }
            });
          } else {
            this.mostrarMensaje(response.data.data.mensaje);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.enviando = false;
        });
    },
    mostrarMensaje(mensaje) {
      this.$swal({
        icon: "error",
        title: `${mensaje}`,
        confirmButtonText: "OK",
      });
    },
    validMail(email) {
      var expr =
        /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      return expr.test(email);
    },
    obtenerCodigoUsoFactura() {
      this.codigoUsoFactura = null;
      this.listaCodigoFactura = [];
      let fisica;
      let moral;
      if (this.rfc.length === 13) {
        fisica = true;
        moral = false;
      } else if (this.rfc.length === 12) {
        fisica = false;
        moral = true;
      }
      this.rfcSeleccionado = this.listaRfcPaciente.find(
        (r) => r.rfc === this.rfc
      );
      if (this.rfc.length >= 12) {
        this.loaderCFDI = true;
        const datos = {
          personaFisica: fisica,
          personaMoral: moral
        }
         const url= this.$baseBack + "/api/erp/portal/codigoUsoFactura";
        this.$http
          .post(url, datos)
          .then((respuesta) => {
            if (respuesta.data.data) {
              this.listaCodigoFactura = respuesta.data.data;
            }
          })
          .finally(() => {
            this.loaderCFDI = false;
          });
      }
    },
    agregar() {
      this.datoFiscal = {
        idPaciente: null,
        rfc: null,
        tipoPersona: 2,
        nombre: null,
        nombreCompleto: null,
        paterno: null,
        materno: null,
        cp: null,
        correo: null,
      };
      this.idTicket2 = localStorage.getItem("idTicket");
      this.obtenerRfcTicketPaciente(2);
      this.showModal = true;
    },
    cerrarModal() {
      this.showModal = false;
    },
    nombre() {
      if (this.datoFiscal.tipoPersona === 1) {
        this.datoFiscal.nombre = "";
        this.datoFiscal.paterno = "";
        this.datoFiscal.materno = "";
        this.datoFiscal.nombreCompleto =
          this.datoFiscal.nombreCompleto.trimStart();
        this.datoFiscal.nombreCompleto =
          this.datoFiscal.nombreCompleto.trimEnd();
      } else {
        this.datoFiscal.nombre = this.datoFiscal.nombre.trimStart();
        this.datoFiscal.paterno = this.datoFiscal.paterno.trimStart();
        this.datoFiscal.nombre = this.datoFiscal.nombre.trimEnd();
        this.datoFiscal.paterno = this.datoFiscal.paterno.trimEnd();
        if (this.datoFiscal.materno !== null) {
          this.datoFiscal.materno = this.datoFiscal.materno.trimStart();
          this.datoFiscal.materno = this.datoFiscal.materno.trimEnd();
        }
      }
    },
    guardar() {
      this.blntieneDatos = false;
      if (this.datoFiscal.tipoPersona === 2) {
        if (
          this.datoFiscal.nombre !== null &&
          this.datoFiscal.paterno !== null &&
          this.datoFiscal.rfc !== null &&
          this.datoFiscal.rfc.length >= 12 &&
          this.datoFiscal.correo !== null &&
          this.validMail(this.datoFiscal.correo) &&
          this.datoFiscal.cp !== null &&
          this.datoFiscal.cp.length === 5
        ) {
          this.datoFiscal.nombreCompleto =
            this.datoFiscal.nombre.trim() +
            " " +
            this.datoFiscal.paterno.trim() +
            " " +
            this.datoFiscal.materno;
          this.blntieneDatos = true;
        }
      } else if (this.datoFiscal.tipoPersona === 1) {
        if (
          this.datoFiscal.nombreCompleto !== null &&
          this.datoFiscal.cp !== null &&
          this.datoFiscal.cp.length === 5 &&
          this.datoFiscal.rfc !== null &&
          this.datoFiscal.rfc.length >= 12 &&
          this.datoFiscal.correo !== null &&
          this.validMail(this.datoFiscal.correo)
        ) {
          this.blntieneDatos = true;
        }
      }
      if (this.blntieneDatos) {
        this.datoFiscal.idPaciente = this.idTitular2;
        this.loaderAcciones = true;
        this.nombre();
        this.$http
          .post(
            this.$baseBack + "/api/portalV2/guardar/datosFiscales",
            this.datoFiscal
          )
          .then((respuesta) => {
            if (respuesta.data.data.idDatosFiscales !== null) {
              this.showModal = false;
              this.obtenerRfcTicketPaciente(1);
              this.guardadoDatosFiscales = true;
              this.$swal.fire({
                icon: "info",
                title: "Los datos se guardó correctamente",
              });
            }
          })
          .catch((reason) => {
            this.$swal.fire({
              icon: "info",
              title: reason.response.data.message,
            });
          })
          .finally(() => {
            this.loaderAcciones = false;
          });
      }
    },
    obtenerRfcTicketPaciente(tickeTipo) {
      this.loaderTicket = true;
      this.rfcSeleccionado = null;
      let tickeDinamico;
      if (tickeTipo === 1) {
        this.listaRfcPaciente = [];
        this.rfc = null;
        tickeDinamico = this.idTicket;
      } else {
        tickeDinamico = this.idTicket2;
      }

      const url =
        this.$baseBack +
        "/api/portalV2/obtener/pacienteRfc";

      const datos = {
        idTicket: tickeDinamico
      };

      this.$http
        .post(url, datos)
        .then((respuesta) => {
          if (respuesta.data.data) {
            if (tickeTipo === 1) {
              this.listaRfcPaciente = respuesta.data.data.listarfcs;
              this.idTitular = respuesta.data.data.paciente;
            }
            if (tickeTipo === 2) {
              this.idTitular2 = respuesta.data.data.paciente;
            }
          }
        })
        .finally(() => {
          this.loaderTicket = false;
        });
    },
    listaFiscal() {
      const datos = {
        idTipoPersona: this.datoFiscal.tipoPersona
      }

      this.$http
        .post(
          this.$api +
            "catalogos/regimenFiscal/obtener/activo", datos
        )
        .then((respuesta) => {
          if (respuesta.data.data) {
            this.regimenFiscales = respuesta.data.data;
          }
        })
        .catch((err) => {
          this.errorMensaje(err);
        });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  #footerLogin {
    position: fixed !important;
    height: 100px !important;
  }
}

.cardPrincipal {
  border: 1px solid #E3E3E3;
  margin: 4%;
  padding: 1.5%;
  border-radius: 20px;
}
</style>
