<template>
  <div>
    <div>
      <Header></Header>
    </div>

    <div class="espaciado" fluid="sm">
      <b-card-group>
        <b-card
            class=""
            header-text-variant="white"
            header="Restablecer contraseña"
            header-tag="header"
        >

          <p>
            Introduzca una nueva contraseña para su cuenta.
            Restablecer su contraseña lo cerrará en todas las sesiones.
            Deberá ingresar su nueva contraseña en todos sus dispositivos.
          </p>
          <b-form @submit="onSubmit">
            <b-form-group
                label="Contraseña"
                label-for="input-password"
            >
              <b-input-group class="mt-3">
                <b-form-input
                    id="input-password"
                    v-model="password"
                    :type="mostrarPassword ? 'text':'password'"
                    :state="cumpleCondicionesPassword"
                    aria-describedby="input-1-live-feedback"
                    required
                ></b-form-input>
                <b-input-group-append>
                  <b-button @click="mostrarPassword = !mostrarPassword" variant="outline-secondary">
                    <b-icon :icon="mostrarPassword ? 'eye-slash-fill':'eye-fill'" aria-label="Mostrar contraseña"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!--Confirmar contraseña-->

            <b-form-group
                label="Confirmar contraseña"
                label-for="input-confirma-password"
            >
              <b-input-group class="mt-3">
                <b-form-input
                    id="input-confirma-password"
                    v-model="confirmarPassword"
                    :type="mostrarCoincidePassword ? 'text':'password'"
                    :state="coincidenPasswords"
                    aria-describedby="input-1-live-feedback"
                    onpaste="console.log('No puedes pegar');return false"
                    required
                ></b-form-input>
                <b-input-group-append>
                  <b-button @click="mostrarCoincidePassword = !mostrarCoincidePassword" variant="outline-secondary">
                    <b-icon :icon="mostrarCoincidePassword ? 'eye-slash-fill':'eye-fill'" aria-label="Mostrar contraseña"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <div>
              <b-alert show variant="primary">
                <p class="alert-heading">La contraseña debería cumplir con los siguientes requerimientos:</p>

                <p style="margin-bottom: 0px;"
                   v-bind:class="longitud ? 'text-success':'text-danger'">
                  <b-icon
                      :icon="longitud ? 'check-circle':'x-circle'"
                      :variant="longitud ? 'success':'danger'">
                  </b-icon>
                  Debería tener <b>16 caracteres</b> como mínimo.
                </p>

                <p style="margin-bottom: 0px;"
                   v-bind:class="minusculas ? 'text-success':'text-danger'">
                  <b-icon
                      :icon="minusculas ? 'check-circle':'x-circle'"
                      :variant="minusculas ? 'success':'danger'">
                  </b-icon>
                  Debe tener: Al menos debería tener <b>una letra minúscula</b>.
                </p>

                <p style="margin-bottom: 0px;"
                   v-bind:class="mayusculas ? 'text-success':'text-danger'">
                  <b-icon
                      :icon="mayusculas ? 'check-circle':'x-circle'"
                      :variant="mayusculas ? 'success':'danger'">
                  </b-icon>
                  Debe tener: Al menos <b>una letra en mayúsculas</b>.
                </p>

                <p style="margin-bottom: 0px;"
                   v-bind:class="caracteres ? 'text-success':'text-danger'">
                  <b-icon
                      :icon="caracteres ? 'check-circle':'x-circle'"
                      :variant="caracteres ? 'success':'danger'">
                  </b-icon>
                  Debe tener: Al menos <b>un caracter especial</b> como mínimo.
                </p>

                <p v-bind:class="numeros ? 'text-success':'text-danger'">
                  <b-icon
                      :icon="numeros ? 'check-circle':'x-circle'"
                      :variant="numeros ? 'success':'danger'">
                  </b-icon>
                  Debe tener: Al menos <b>un número</b>.
                </p>

              </b-alert>
            </div>

            <b-button type="submit" variant="outline-primary" :disabled="btnContinuar">
              <span v-show="!mostrarSpinnerContinuar">Continuar</span>

              <b-spinner v-show="mostrarSpinnerContinuar" small></b-spinner>
              <span v-show="mostrarSpinnerContinuar"> Cargando...</span>
            </b-button>
            <b-button v-on:click="onReset" variant="outline-secondary">Atrás</b-button>
          </b-form>
        </b-card>
      </b-card-group>
    </div>
    <div style="margin-top: 50px">
      <footer id="footerLogin">
      </footer>
    </div>
  </div>
</template>

<script>
import Header from "@/components/control-acceso/Header";

export default {
  name: "ResetPassword",
  components: {Header},
  data() {
    return {
      longitud: false,
      minusculas: false,
      mayusculas: false,
      numeros: false,
      caracteres: false,
      btnContinuar: true,

      mostrarPassword: false,
      mostrarCoincidePassword: false,
      mostrarSpinnerContinuar: false,

      password: '',
      confirmarPassword: '',
      codigo: null,
      tipoSistema: Number,
      cumpleCondicionesPassword: null,
      coincidenPasswords: null,
      mensajeAPI: null,
    }
  },
  created() {
    this.codigo = this.$route.params.codigo;
    this.tipoSistema = Number(this.$idSistema);
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.mostrarSpinnerContinuar = true;
      this.btnContinuar = true;
      this.guardarContraseniaUsuario();
    },
    guardarContraseniaUsuario(){
      this.$http
          .post(this.$apiControlAcceso + "guardar/constrasenia", {
            codigo: this.codigo,
            contraseniaNueva: this.confirmarPassword,
            idSistema: this.tipoSistema,
            idUsuarioModificado: 1
          })
          .then((respuesta) => {
            this.mostrarSpinnerContinuar = false
            this.btnContinuar = false;

            if(respuesta.data.data.codigo === 200){
              if(!respuesta.data.data.error){
                this.$swal.fire({
                  icon: "success",
                  title: respuesta.data.data.mensaje,
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push({ name: "Login" })
                  }
                })
              }else{
                this.$swal.fire({
                  icon: "warning",
                  title: respuesta.data.data.mensaje,
                });
              }
            }else{
              this.$swal.fire({
                icon: "error",
                title: respuesta.data.data.mensaje,
              });
            }
          })
    },
    validarPass(){

      if(/[0-9]/.test(this.password))
        this.numeros = true;
      else
        this.numeros = false;

      if(/[a-z]/.test(this.password))
        this.minusculas = true;
      else
        this.minusculas = false;

      if(/[A-Z]/.test(this.password))
        this.mayusculas = true;
      else
        this.mayusculas = false;

      if(/[$%!@.#]/.test(this.password))
        this.caracteres = true;
      else
        this.caracteres = false;

      if(this.password.length >= 16)
        this.longitud = true;
      else
        this.longitud = false;

    },
    onReset(event) {
      event.preventDefault()
      this.$router.push({ name: "Login" })
    }
  },
  watch: {
    password: function (){
      this.validarPass();
      if(this.longitud && this.minusculas && this.mayusculas && this.numeros && this.caracteres){
        this.cumpleCondicionesPassword = true;
      } else {
        this.cumpleCondicionesPassword = false;
      }

      if(this.coincidenPasswords !== null && !this.cumpleCondicionesPassword && !(this.confirmarPassword === this.password)) {
        this.coincidenPasswords = false;
        this.btnContinuar = true;
      } else if (this.cumpleCondicionesPassword && this.confirmarPassword === this.password){
        this.coincidenPasswords = true;
        this.btnContinuar = false;
      }

      if (this.coincidenPasswords !== null) {
        if (!(this.password === this.confirmarPassword)) {
          this.coincidenPasswords = false;
          this.btnContinuar = true;
        }
      }

    },
    confirmarPassword: function () {
      if(this.cumpleCondicionesPassword && this.password === this.confirmarPassword) {
        this.coincidenPasswords = true;
        this.btnContinuar = false;
      } else {
        this.coincidenPasswords = false;
        this.btnContinuar = true;
      }
    }
  },
  computed: {
    textoColor: function () {
      return {
        active: this.longitud, 'text-danger': 'text-success'
      }
    }
  }
}
</script>

<style scoped>


.espaciado {
  margin: 3%;
}

.card-header:first-child {
  background-color: #0e6ba9 !important;
}
</style>