<template>
  <div>


    <b-overlay :show="cargando" variant="light" opacity="1">
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="4"
            animation="cylon"
            class="colorPrincipal"
          ></b-icon>
          <h4 class="colorPrincipal">Cargando...</h4>
        </div>
      </template>

      <div class="noAlTope pantallaCompleta">
        <b-card
          class="ventana"
          header-class="fondoSecundario"
          header-tag="header"
          no-body
        >
          <template #header>
            <h4>¡Error de compra!</h4>
          </template>

          <div class="noAlTope" v-if="tipoError == 0">
            <b-alert show variant="danger">
              <h3 class="mb-4">Hubo un error al realizar el pago</h3>
              <ul>
                <li>
                  Asegúrate de haber elegido el tipo de tarjeta, el número y la
                  fecha de vencimiento correcta.
                </li>
                <li>
                  Verifique que el código de seguridad de la tarjeta esté
                  correcto.
                </li>
              </ul>
            </b-alert>

            <div class="text-center">
              <router-link :to="{ name: 'CarritoPago' }">
                <b-button class="DosSecundario">
                  <b-icon icon="cash-stack" animation="cylon-vertical"></b-icon>
                  Realizar pago
                </b-button>
              </router-link>
            </div>
          </div>

          <div class="noAlTope" v-if="tipoError == 1">
            <b-alert show variant="danger">
              <h3 class="mb-4">Error al ingresar los beneficiarios</h3>

              <p>
                Hubo un problema al ingresar los datos de los beneficiarios, por
                favor da click en el siguiente enlace para agregarlos.
              </p>

              <p><strong>Nota:</strong> El pago se creó con exito.</p>
            </b-alert>

            <div class="text-center mb-4">
              <b-button
                class="DosSecundario"
                :disabled="enviando"
                v-on:click="iniciar"
                v-animate-css.click="bounceInOption"
              >
                <b-icon icon="people-fill" animation="fade"></b-icon>
                Guardar beneficiarios
              </b-button>
            </div>

            <b-progress
              v-if="enviando"
              :max="tiempoMaximo"
              height="3.5rem"
              striped
              animated
            >
              <b-progress-bar
                class="UnoPrincipal"
                label="GUARDANDO"
                :value="tiempoCarga"
              ></b-progress-bar>
            </b-progress>
          </div>
        </b-card>
      </div>
    </b-overlay>

    <pie :login="false" />
  </div>
</template>

<script>
import pie from "../components/Footer.vue";

export default {
  name: "PagoError",
  components: {
    pie,
  },
  data: () => ({
    tipoError: null,
    bounceInOption: {
      classes: "bounceIn",
      duration: 600,
    },
    tiempoCarga: 0,
    tiempoMaximo: 15,
    enviando: false,
    datosGuardados: false,
    cargando: false,
  }),
  created() {
    this.tipoError = this.$route.params.tipoError;
    this.traerTipoError();
  },
  methods: {
    traerTipoError() {
      this.cargando = false;
    },
    iniciar() {
      setInterval(() => {
        this.datosGuardados = true;
      }, 3000);
      //si fallo al ingresar igual en el catch mandar true
      this.guardarBeneficiarios();
    },
    guardarBeneficiarios() {
      this.datosGuardados = false;
      this.enviando = true;
      this.tiempoCarga = 0;
    },
    inicioCargando() {
      this.tiempoCarga = 0;
      this.enviando = true;
    },
    limpiandoCarga() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
  },
  watch: {
    enviando(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.limpiandoCarga();
        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.tiempoCarga++;
            if (this.tiempoCarga == 7) {
              this.tiempoCarga = 13;
            }
          }, 500);
        }
      }
    },
    tiempoCarga(newValue, oldValue) {
      if (newValue !== oldValue && this.datosGuardados) {
        this.enviando = false;

        if (this.datosGuardados) {
          this.$swal({
            icon: "success",
            title: "Se ingresaron los datos correctamente",
            confirmButtonText: "OK",
            onClose: () => {
              this.$router.push({ name: "PagoGracias" });
            },
          });
        }
      }
    },
  },
};
</script>
