<template>
  <div>
    <p class="subtitulosDetalle">Resumen de compra</p>
    <div class="d-flex justify-content-between mt-5" style="font-size: 0.4em">
      <p>Planes seleccionados</p>
      <p>{{ carritoPlanes.length }}</p>
    </div>

    <div class="d-flex justify-content-between mt-4" style="font-size: 0.4em">
      <p>Cantidad de pacientes</p>
      <p>{{ totalPlanes }}</p>
    </div>

    <div class="d-flex justify-content-between mt-4" style="font-size: 0.4em">
      <p>Total</p>
      <p style="color: #0069AA; font-weight: 700;">{{ total | moneda }}</p>
    </div>
    <div class="d-flex justify-content-center mb-3" style="margin-top: 20%">
      <b-button
        v-if="carritoPlanes && carritoPlanes.length > 0"
        class="DosSecundario"
        v-on:click="guardarCarritoTemporal()"
        variant="primary"
        style="margin-top: 55%;"
      >Continuar</b-button>
    </div>
    <div id="nota">
    </div>
  </div>
</template>

<script>
export default {
  name: "ResumenCompra",
  props: {
    pago: {
      type: Boolean,
      require: true,
    },
  },
  data: () => ({
    total: 0,
    carritoPlanes: null,
    totalPlanes: 0,
  }),
  created() {
    this.carritoPlanes = this.$session.get("carrito");
    this.calcular();
    this.$root.$on("carritonav", () => {
      this.calcular();
    });
  },
  computed: {
  
  },
  methods: {
    guardarCarritoTemporal() {
      let lista = this.$session.get("carrito");
      const planes = lista.map((plan) => {
        plan.imagen = null;
        plan.pacientes = [...Array(plan.numeroMaximo).keys()].map(() => ({}));
        return plan;
      });
      let nuevoCarrito = planes.map((plan) => {return [...Array(plan.cantidad).keys()].map(() => ({...plan, cantidad: 1,}));}).reduce((a, b) => a.concat(b));
      this.$session.set('carrito', nuevoCarrito);
      this.$root.$emit("carritonav");
      this.$router.push({ name: "ConfiguracionMembresia" });
    },
    calcular() {
      this.totalPlanes = 0;
      
      const carrito = this.$session.get("carrito");
      this.carritoPlanes = carrito;
      if (carrito && carrito.length > 0) {
        this.total = this.$session
          .get("carrito")
          .map((plan) => plan.cantidad * plan.precio)
          .reduce((a, b) => a + b);

      carrito.forEach(element => {
        this.totalPlanes += element.cantidad * element.numeroMaximo;
      });
      } else {
        this.total = 0;
      }
    },
  },
};
</script>

<style></style>

