<template>
  <div></div>
</template>

<script>
export default {
  name: "LoginToken",
  data: () => ({
    mensajeError: "Usuario o contraseña incorrectos",
    sistemaOperativo: "",
    lat: "",
    long: "",
    navegador: "",
    ipCliente:"",
     blnContUsu: false
  }),
  created() {
  this.obtenerIp();
  this.obtenerUbicacion();
  this.sistemaOperativo = this.obtenerSO();
  this.navegador = this. obtenerNavegador();
  },
  mounted() {
    this.verificarTokenAccesoPortal();
  },
  methods: {
    verificarTokenAccesoPortal() {
      this.$session.destroy();
      const url = this.$baseBackControlAcceso + "/login";
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      };

      let parametros = {
        nombreUsuario: null,
        contrasena: this.$route.params.token,
        sistema: 3
      };

      this.$http
        .post(url,parametros,  {headers})
        .then((response) => {
          this.$session.set("token", response.headers.authorization);
          this.$session.set("idControlAcceso", response.headers.CONTROL);
        })
          .then(() => {
            this.$http.post(this.$baseBack + '/me').then( response => {
              this.$session.start();
              this.$localStorageService.asignarUsuarioAutenticado(response.data.data);
            }).then(()=> {
              if (this.$localStorageService.getError()) {
                this.mostrarMensajeError(
                    this.$localStorageService.getTipoError()
                );
              } else {
                this.$session.set('esPlan', true);
                this.$router.push({ name: "Planes" });
              }
            })
           setTimeout(()=>{
             if(this.$localStorageService.getError() == false){
                this.guardarBitacora("EXITO", this.$localStorageService.getIdUsuario());
                }
             },5000);
          })
        .catch((err) => {
          this.mostrarMensajeError(this.mensajeError);
          console.log(err);
          this.blnContUsu = true;
          this.guardarBitacora("USUARIO Y CONTRASEÑA INCORRECTA", null);
        })
        .finally(() => {
           setTimeout(()=>{
            if(this.$localStorageService.getTipoError().toUpperCase().indexOf("LA CAMPAÑA HA TERMINADO") >= 0 && !this.blnContUsu){
                    this.guardarBitacora("LA CAMPAÑA HA TERMINADO", this.$localStorageService.getIdUsuario());
              }
            }, 5000);
         });
    },
      obtenerUbicacion() {
        navigator.geolocation.getCurrentPosition(
            response => {
                this.lat =  response.coords.latitude.toString();
                this.long = response.coords.longitude.toString();
            }, error => {
                console.log(error);
            });
     },
    guardarBitacora(evento, usuario) {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      };
      const url = this.$baseBack + "/bitacoraLogin/guardar";
          let enviarDatos= {
           canal: 24,
           evento: evento,
           navegador: this.navegador,
           sistemaOperativo: this.sistemaOperativo,
           ipOrigen: this.ipCliente,
           latitud: this.lat,
           longitud: this.long,
           idUsuario: usuario
          }
      this.$http
          .post(url, enviarDatos,{headers: headers})
          .then((response) => {
            if (response.data.data) {
               console.log(response.data);
            }
          })
          .catch((err) => {
            console.log(err);
          })
    },
     obtenerSO(){
        if (window.navigator.userAgent.indexOf('Windows') > -1) {
         return "WINDOWS";
        } else if (window.navigator.userAgent.indexOf('Mac') > -1) {
            return "MAC";
        } else if (window.navigator.userAgent.indexOf('X11') > -1) {
           return  "UNIX";
        } else if (window.navigator.userAgent.indexOf('Linux') > -1) {
            return "LINUX";
        }else{
          return "OTRO"
        }
     },
     obtenerIp(){
     fetch('https://api.ipify.org?format=json')
        .then(x => x.json())
            .then(({ ip }) => {
              console.log(typeof ip);
             this.ipCliente = ip;
             console.log(typeof this.ipCliente);
             console.log(this.ipCliente);
          });
     },
     obtenerNavegador(){
       if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
         return 'CHROME';
        } else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            return 'FIREFOX';
        } else if (navigator.userAgent.toLowerCase().indexOf('opera') > -1) {
           return  'OPERA';
        } else if (navigator.userAgent.toLowerCase().indexOf('MSIE') > -1) {
            return 'INTERNET EXPLORER';
        }else if (navigator.vendor ==  "Apple Computer, Inc.") {
            return 'SAFARI';
        }else{
          return "OTRO"
        }
     },
    mostrarMensajeError(mensaje) {
      this.$swal({
        icon: "error",
        title: `${mensaje}`,
        confirmButtonText: "OK",
      });
    },
  },
};
</script>
