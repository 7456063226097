<template>
  <div>
    <div
        v-if="carritoPacientes == undefined || carritoPacientes.length == 0"
        class="noAlTope text-center pantallaCompleta"
    >
      <notaCarritoVacio/>
    </div>

    <div class="noAlTope pantallaCompleta cargando">
      <b-progress :max="tiempoMaximo" height="3.5rem">
        <b-progress-bar
            class="UnoPrincipal"
            label="VERIFICANDO Y GUARDANDO COMPRA"
            :value="tiempoCarga"
        ></b-progress-bar>
      </b-progress>
    </div>

  </div>
</template>

<script>
import notaCarritoVacio from "../components/NotaCarritoVacio.vue";

export default {
  name: "CarritoFin",
  props: {
    datosPagos: null
  },
  components: {
    notaCarritoVacio
  },
  data: () => ({
    tiempoCarga: 0,
    tiempoMaximo: 100,
    cargando: false,
    envioCargando: false,
    error: null,
    carritoPacientes: null,
    idTicket: null,
    listaCertificados: [],
  }),
  created() {

    this.iniciar();

  },
  methods: {
    iniciar() {
      this.tiempoCarga = 0;
      this.cargando = true;
      this.guardarTicket();
    },
    enlazarPantallafinal() {
      if (this.error != null) {
        this.$router.push({
          name: "PagoError",
          params: {
            tipoError: this.error,
          },
        });
      } else {
       localStorage.setItem('idTicket', this.idTicket);
        this.$router.push({
          name: "PagoGracias"
        });
      }
    },
    guardarTicket() {
      const url = this.$api + "plan/titular";
      this.$http
          .post(url, this.traerDatosTitular())
          .then((response) => {
            if (response.data.data) {
              this.idTicket = response.data.data;
              this.guardarPagoPaciente();
            }
          })
          .catch((err) => {
            console.log(err);
            this.error = 1;
          });

    },
    guardarPagoPaciente() {
      const url = this.$api + "plan/pago";
      this.$http
          .post(url, this.traerDatos())
          .then((response) => {
            if (response.data.data) {
              this.listaCertificados = response.data.data;
              this.enviarCorreoPaciente();
            }
          })
          .catch((err) => {
            console.log(err);
            this.error = 2;
          });
    },
    enviarCorreoPaciente() {
      const url = this.$api + "certificado/correo";
      const parametros = {
        listaCertificados: this.listaCertificados,
        idTicket: this.idTicket,
        facturacionLinea : this.$baseFacturacion
      };
      this.$http
          .post(url, parametros)
          .then((response) => {
            if (response.data.data) {
              this.tiempoCarga = 90;
            }
          })
          .catch(() => {
            this.error = 3;
          });
    },
    traerDatosTitular() {
      const titular = this.$session.get('titular');
      return {
        tipoTarjeta: '',
        ultimosDigitos: '',
        montoPagar: this.$session.get('carrito').map(plan => plan.cantidad * plan.precio).reduce((a, b) => a + b),
        nombre: titular.nombrePaciente.toUpperCase(),
        apellidos: titular.apellidoP.toUpperCase() + ' ' + titular.apellidoM.toUpperCase(),
        telefono: titular.telefono,
        correo: titular.correo,
        montoPagarSinComisiones: this.$session.get('carrito')[0].precioSinComision,
        comision: this.$session.get('carrito')[0].comision,
        idReferencia: this.$session.get('referencia'),
        pagoRecurrente:this.$session.get('pagoRecurrente'),
        msi:this.$session.get('idMsiSeleccionado'),
        totalPlanes: this.$session.get('carrito').length,
        aceptoTerminosCodiciones:this.$session.get('aceptaTerminos')
      };
    },
    traerDatos() {
      return {
            beneficiarios: this.$session.get('pacientes').map(it => ({
          idPlan: it.idPlan,
          idParentesco: it.parentesco,
          idTipoAsegurado: it.tipoAsegurado,
          idPaciente: it.idPaciente,
          nombreVendedor: it.nombreVendedor,
          pagoRecurrente: it.pagoRecurrente
        })),
        idTicket: this.idTicket,
        idEmpresa: this.$localStorageService.getIdEmpresa(),
      };
    },
    limpiandoCarga() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
  },
  watch: {
    cargando(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.limpiandoCarga();
        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.tiempoCarga++;
            if (this.tiempoCarga == 45) {
              this.tiempoCarga = 70;
            }
          }, 100);
        }
      }
    },
    tiempoCarga(newValue, oldValue) {
      if (newValue !== oldValue && newValue === this.tiempoMaximo) {
        this.cargando = false;
        this.enlazarPantallafinal();
      }
    },
  },
};
</script>

<style>
</style>
