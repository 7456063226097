import moment from 'moment';


const FuncionesAyuda = (function () {

    function getTextoLimpioMayus(texto) {
        return texto.trim().toLowerCase().toUpperCase();
    }

    function getTextoLimpioMinus(texto) {
        return texto.trim().toLowerCase();
    }

    function getFecha(fecha) {
        const nuevaFecha = moment(fecha).format("YYYY-MM-DD");
        return moment(nuevaFecha).utc().format("YYYY-MM-DD[T]HH:mm:ss[Z]");
    }

    function soloLetras(e) {

        const isString = (tecla) => {
            const patron = /[A-Za-z]/;
            const tecla_final = String.fromCharCode(tecla);
            if (!patron.test(tecla_final)) {
                e.preventDefault();
                e.target.value = '';
                return false;
            } else {
                return true;
            }
        }
        const pasteEvento = (pasteEvent) => {

            let content = pasteEvent.clipboardData.getData('text');

            return isString(content)

        }


        const keyEvent = (e) => {

            const tecla = (document.all) ? e.keyCode : e.which;
            //Tecla de retroceso para borrar, siempre la permite
            if (tecla == 8 || e.key == 'ñ' || e.key == 'Ñ' || e.key == ' ') {
                return true;
            }
            return isString(tecla)

        }

        switch (e.type) {
            case 'keypress': {
                return keyEvent(e);
            }
            case 'keyup': {
                return keyEvent(e);
            }
            case 'paste': {
                return pasteEvento(e)
            }
        }


    }

    const funcionMoneda = function (value) {
        if (value === undefined || value === null) {
            return "";
        } else {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(value);
        }
    }
    const formatoFechaFuncion = function (value) {
        if (value !== undefined && value !== null && value !== 'undefiend') {
            return moment(value).format("DD/MMMM/YYYY");
        }
    }
    const onlyNumber = (evt) => {

        const keyEvent = (keyevt) => {
            if (isNumeric(keyevt.key) || keyevt.key === '.' && keyevt.target.value.split('.').length <= 2) {
                return true;
            } else {
                keyevt.target.value = '';
                keyevt.preventDefault();
                return false;
            }
        }
        const pasteEvento = (pasteEvent) => {

            console.log(evt)
            let content = evt.clipboardData.getData('text');
            content = content.split(' ').join('').trim();
            if (isNumeric(content)) {
                pasteEvent.target.value = content;
                return true;
            } else {
                pasteEvent.target.value = '';
                pasteEvent.preventDefault();
                return false;
            }
        }

        const isNumeric = (str) => {
            if (typeof str != "string")
                return false // we only process strings!
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                !isNaN(parseFloat(str))
        }

        if (evt)
            switch (evt.type) {
                case 'keypress': {
                    return keyEvent(evt);
                }
                case 'keyup': {
                    return keyEvent(evt);
                }
                case 'paste': {
                    return pasteEvento(evt)
                }
            }

    }

    return {
        textoMayus: getTextoLimpioMayus,
        textoMinus: getTextoLimpioMinus,
        traerFecha: getFecha,
        soloLetras: soloLetras,
        funcionMoneda: funcionMoneda,
        formatoFechaFuncion: formatoFechaFuncion,
        onlyNumber: onlyNumber
        // traerFechaHora: getFechaHora,
        // traerFechaActual: getFechaActual,
        // traerFechaHoraActual: getFechaHoraActual,
        // traerFechaLocal: getFechaLocal,
        // traerFechaHoraLocal: getFechaHoraLocal,
        // traerHora: getHora,
        // groupBy: groupBy
    }
})();
export default FuncionesAyuda;
