<template>
  <div>
    <PortalVista :configuracion="true">
      
      <template #header>
        <p class="text-titulo">Configuración de pacientes</p>
      </template>
      <template #content>
        <div>
          <FormularioPaciente
          :planSeleccionado="valorDesdeResumenCompraLocal"
          :traerPacientes="traerPacientesLocal"/>
        </div>
      </template>

      <template #detallePlan>
        <div
          class="p-4"
          style="
            background-color: white;
            border-radius: 20px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.205);
          "
        >
          <PlanSeleccionado
            @actualizarValor="actualizarValor"
            @traerPacientes ="traerPacientes"
            :pago="false"
          ></PlanSeleccionado>
        </div>
      </template>
    </PortalVista>
  </div>
</template>

<script>
import FormularioPaciente from "../../components/nuevo_portal/FormularioConfiguracionPacientes.vue";
import PlanSeleccionado from "../../components/nuevo_portal/PlanSeleccionadoConfiguracion.vue";
import PortalVista from "../../components/nuevo_portal/VistaPlanes.vue";


export default {
  name: "CarritoPaciente",
  components: {
    PortalVista,
    PlanSeleccionado,
    FormularioPaciente,
  },
  props: {
    valorDesdeResumenCompra: Number,
    traerPacientesLocal: Boolean
  },
  data() {
    return {
      valorDesdeResumenCompraLocal: null,
    };
  },
  methods: {
    actualizarValor(nuevoValor) {
      this.valorDesdeResumenCompraLocal = nuevoValor;
    },
    traerPacientes(traerPacientes){
   
      this.traerPacientesLocal = traerPacientes;
    }
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .letraResposivaTitulo {
    font-size: 6vw !important;
  }
}
</style>
