<template>
  <div>
    <div>
      <div class="text-center" id="loadImg">
        <b-spinner></b-spinner>
      </div>
      <iframe
        width="100%"
        height="800"
        scrolling="no"
        seamless="seamless"
        :src="url"
        v-if="url !== null"
        frameborder="0"
        onload="document.getElementById('loadImg').style.display='none';"
      ></iframe>
      <hr />
      <div
        class="mb-1"
        v-if="blnRegresarCarritoPaciente"
        @click="regresarCarritoPaciente()"
      >
        <b-button block>Regresar al método de pago</b-button>
      </div>
      <p>
        Al dar clic en "Finalizar compra" aceptas que se haga un cargo a tu
        tarjeta de crédito o débito. En caso de que la transacción sea exitosa,
        te enviaremos un correo electrónico con información para obtener los
        certificados correspondientes a tus planes adquiridos. Los pagos son
        procesados por <strong>Mercado pago</strong>.
      </p>
    </div>
  </div>
</template>

<script>
import convert from "xml-js";
// import { SocketService } from "../socket/SocketService";

export default {
  name: "FormularioPagoMultiva",
  components: {},
  data: () => ({
    url: null,
    myInterval: null,
    idReferencia: null,
    blnRegresarCarritoPaciente: false,
  }),
  created() {
    this.$session.set("step", 3);
  },
  mounted() {
    this.traerCadena();
  },
  destroyed() {
     clearInterval(this.myInterval);
  },
  methods: {
    traerCadena() {
      const url = this.$baseBack + "/integraciones/multiva/generar/liga/pago";

      const pacientes = this.$session
        .get("carrito")
        .map((plan) =>
          plan.pacientes.map((p) => ({
            ...p,
            plan: plan.nombrePlan,
            idPlan: plan.idPlan,
          }))
        )
        .reduce((a, b) => [...a, ...b]);

      let titular = pacientes.find((p) => p.idTipoAsegurado === 1);
      if (!titular) {
        titular = pacientes[0];
      }

      this.$session.set("pacientes", pacientes);
      this.$session.set("titular", titular);

      const datos = {
        form: {
          monto: this.$session
            .get("carrito")
            .map((plan) => plan.cantidad * plan.precio)
            .reduce((a, b) => a + b),
          mailCliente: titular.correo,
          promociones: this.$session.get("promocion"),
          datosAdicionales: pacientes.map((paciente) => ({
            id: pacientes.indexOf(paciente) + 1,
            display: true,
            label: paciente.plan,
            value:
              paciente.nombrePaciente +
              " " +
              paciente.apellidoP +
              " " +
              paciente.apellidoM,
          })),
        },
        pacientes,
      };

      this.$http
        .post(url, datos)
        .then((response) => {
          var result1 = JSON.parse(
            convert.xml2json(response.data.data.respuestaOriginal)
          );
          this.url = result1.elements
            .find((e) => e.name === "P_RESPONSE")
            .elements.find((p) => p.name === "nb_url")
            .elements.find((u) => u.type === "text").text;
          this.idReferencia = response.data.data.referenciaPago;
          this.$session.set("referencia", this.idReferencia);
          /*const soket = new SocketService();
          soket.io.on(titular.correo, (r) => {
            if (r === "approved") {
              this.$router.push({ name: "CarritoFin" });
            }
          });*/
        })
        .catch((err) => {
          this.errorTraerDatos(err);
        })
        .finally(() => {
          if (window.location.href.indexOf("carrito/pago") >= 0) {
            this.myInterval = setInterval(this.verficarRealizoPago, 30000);
          }
        });
    },
    verficarRealizoPago() {
      const url =
        this.$baseBack +
        "/integraciones/multiva/verificar/pago?idReferencia=" +
        this.idReferencia;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.data.realizoPago === true && response.data.data.mensaje ==="Se realizo la compra correctamente") {
            if (window.location.href.indexOf("carrito/pago") >= 0) {
              this.$swal({
                    icon: "success",
                    title: response.data.data.mensaje,
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                      this.$session.set("referencia", this.idReferencia);
                      this.$router.push({ name: "CarritoFin" });
                      clearInterval(this.myInterval)
                  }
                });
            }
          }
          if (response.data.data.realizoPago === false && response.data.data.mensaje !=="Se realizo la compra correctamente" && response.data.data.mensaje !== null) {
            if (window.location.href.indexOf("carrito/pago") >= 0) {
                this.$swal({
                    icon: "error",
                    title: response.data.data.mensaje,
                    confirmButtonText: "OK",
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                     window.location.reload();
                     clearInterval(this.myInterval);
                  }
                })
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
                    icon: "error",
                    title: "Error en el pago",
                    confirmButtonText: "OK",
                })
        });
    },
    regresarCarritoPaciente() {
      this.$router.push({ name: "CarritoPago" });
    },
  },
};
</script>

<style>
</style>
