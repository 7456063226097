<template>
  <div>

    <b-overlay :show="cargando" variant="light" opacity="1">
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="4"
            animation="cylon"
            class="colorPrincipal"
          ></b-icon>
          <h4 class="colorPrincipal">Cargando...</h4>
        </div>
      </template>

      <div class="noAlTope pantallaCompleta">
        <b-card
          class="ventana"
          header-class="fondoSecundario"
          header-tag="header"
          no-body
        >
          <template #header>
            <b-row>
              <b-col>
                <h4>¡Gracias por tu compra!</h4>
            </b-col>
            <b-col></b-col>
            <b-col style="text-align:end">
                <b-button
                  class="mt-2 mb-2 DosSecundario"
                  v-on:click="requerirInicio"
                  v-if="blnHabiltarBtnFacturar"
                >
                  <b-icon icon="house-fill" animation="throb"></b-icon>
                  Ir al Inicio
                </b-button>
            </b-col>
            </b-row>
          </template>

          <div class="noAlTope">
            <p class="mb-4">
              Se te ha enviado un correo a la dirección que registraste en tu
              forma de pago ( <strong>{{ correo }}</strong> ). En él encontrarás
              información importante
              <strong> relacionada con tu compra</strong>, así como un
              <strong> link para descargar el certificado</strong> de cada uno
              de tus planes adquiridos.
            </p>

            <div v-if="listaCertificados.length != 0">
              <b-button v-b-toggle.collapse-1 class="UnoPrincipal">
                <b-icon icon="list"> </b-icon>
                Descarga tus certificados
              </b-button>
              <b-collapse id="collapse-1" class="mt-2">
                <b-card>
                  <ul class="mt-3">
                    <li
                      v-for="cert in listaCertificados"
                      :key="cert.idCertificado"
                    >
                      <a
                        class="underlineHover"
                        v-on:click="
                          traerCertificadoPdf(
                            cert.nombreCertificado,
                            cert.token
                          )
                        "
                      >
                        {{ cert.nombreCertificado }}
                      </a>
                    </li>
                  </ul>
                </b-card>
              </b-collapse>
            </div>

            <p class="mt-4">
              A partir de este momento, ya puedes aprovechar los beneficios de
              tus planes dentales en cualquiera de nuestras clínicas. Ya
              contamos con tus datos registrados en sistema, sólo tienes que
              presentar tu credencial para votar o alguna otra identificación
              con fotografía.
            </p>

            <b-alert variant="primary" show>
              <h4>
                <strong> Estimado usuario:</strong>
              </h4>
              <p>
                En caso de
                <strong>
                  NO recibir la liga de descarga de su certificado en el correo
                  de confirmación de la compra de su plan,
                </strong>
                agradeceremos nos envié un correo a la cuenta
                <a class="underlineHover" href="mailto:ventas@dentalia.com">
                  ventas@dentalia.com
                </a>
                con la siguiente información:
              </p>

              <ul>
                <li>Nombre completo titular</li>
                <li>Nombre completo dependiente</li>
                <li>Fecha de nacimiento titular</li>
                <li>Fecha de nacimiento dependiente</li>
                <li>Fecha de compra</li>
                <li>Tipo de plan</li>
              </ul>
            </b-alert>

            <b-row>
              <b-col class="text-center">
                <b-button
                  class="mt-4 mb-4 DosSecundario"
                  v-on:click="requerirFactura"
                >
                  <b-icon icon="receipt-cutoff" animation="throb"></b-icon>
                  Requiero facturar
                </b-button>

                <p>
                  En
                  <strong>
                    dentalia<sub>&copy;</sub> queremos verte feliz
                    <sub>&copy;</sub>
                  </strong>
                </p>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>

    <pie :login="false" />
  </div>
</template>

<script>
import pie from "../components/Footer.vue";

export default {
  name: "PagoGracias",
  components: {
    pie,
  },
  data: () => ({
    idTicket: null,
    correo: "",
    listaCertificados: [],
    cargando: true,
    blnHabiltarBtnFacturar: null
  }),
  created() {
    this.idTicket = localStorage.getItem('idTicket')
    this.traerCorreoCompra();
    this.traerListaCertificados();

    let datosPortal = this.$session.get("token");
    //this.$session.clear();
    this.$session.set("token", datosPortal);
    this.validacionFacturaPortalComercial();
    window.location.hash="no-back-button";
    window.location.hash="Again-No-back-button";
    window.onhashchange=function(){window.location.hash="no-back-button";}
  },
  methods: {
    traerCorreoCompra() {
      const infoCertificado = {
        idTicket: this.idTicket
      };

      const url = this.$api + "certificado/ticket/correo";

      this.$http
        .post(url, infoCertificado)
        .then((response) => {
          if (response.data.data) {
            this.correo = response.data.data;
          }
        })
        .catch((err) => {
          this.errorTraerDatos(err);
        });
    },
    traerListaCertificados() {
      const infoCertificado = {
        idTicket: this.idTicket
      };

      const url = this.$api + "certificado/ticket";
      this.$http
        .post(url, infoCertificado)
        .then((response) => {
          this.listaCertificados = response.data.data;
        })
        .catch((err) => {
          this.errorTraerDatos(err);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    traerCertificadoPdf(nombrePaciente, tokenCertificado) {
      const infoCertificado = {
        ticketToken: tokenCertificado
      };

      const url = this.$api + "certificado/obtenerInfoTicket";
      this.$http
        .post(url, infoCertificado)
        .then((response) => {
          if (response.data.data != null) {
            this.descargarPdf(nombrePaciente, response.data.data);
          }
        })
        .catch((err) => {
          this.errorTraerDatos(err);
        });
    },
    descargarPdf(nombre, datos) {
      const byteCharacters = atob(datos);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = nombre;
      link.click();
    },
    requerirFactura() {
      this.$router.push({ 
        name: "Facturacion"
        });
    },
      validacionFacturaPortalComercial() {
      const url = this.$baseBack + "/api/erp/validacionFacturaPortalComercial";
      this.$http
        .get(url)
        .then((response) => {
          if(response.data.data !== null){
            this.blnHabiltarBtnFacturar = response.data.data;
           }
         })
         .catch((err) => {
          console.log(err);
        });
     },
     requerirInicio() {
      this.$router.push({ 
        name: "Planes"
        });
    }
  },
  notifications: {
    errorTraerDatos: {
      type: "error",
      title: "Error de servicio",
      message: "Error al traer los datos",
    },
  },
};
</script>

<style>
</style>
