<template>
  <div id="example1" class="fondoAzulCielo">
    <div class="dienteInferior d-flex align-items-end">
      <img
          :src="require('../assets/icons/diente_trans.png')"
      />
    </div>
    <div class="dienteCentroDer d-flex align-items-center justify-content-end">
      <img
          :src="require('../assets/icons/diente_completo_trans.png')"
      />
    </div>
    <div class="centered whiteDivContainer pb-4">
      <div class="h-100 d-flex justify-content-center align-items-center flex-column">
        <img
            class="imgSize"
            :src="require('../assets/logo_dentalia.png')"
        />
        <h2 class="m-4" style="color: #168AAD;">¡Ups! Ocurrió un error</h2>
        <h5 class="m-1">Por favor, póngase en contacto con nosotros o inténtelo nuevamente.</h5>

        <b-button variant="primary" class="m-3" style="background: #168AAD;" @click="regresarCarritoPaciente">Regresar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RespuestaError",
  methods: {
    regresarCarritoPaciente() {
      //this.$router.go(-1)
      this.$router.push({ name: "Planes" });
    }
  }
}
</script>

<style scoped>
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.whiteDivContainer {
  height: 93vh;
  border-radius: 20px;
  width: 90%;
  background-color: white;
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.dienteInferior {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.dienteCentroDer {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.dienteInferior img {
  z-index: 1000;
}

.dienteCentroDer img {
  z-index: 1000;
}

.imgSize {
  width: 260px;
  height: 120px;
}

.fondoAzulCielo {
  height: 100vh;
  background: linear-gradient(180deg, #F1F9FF 0%, #FFFFFF 0.01%, #F0F9FF 100%);
}

@media (max-width: 900px) {
  .whiteDivContainer {
    height: 110vh !important;
  }
}
</style>
