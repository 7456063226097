<template>
  <div class="d-flex justify-content-center">
    <ul class="wizard">
      
      <li ui-sref="firstStep wizard" :class="{ 'active': step === 1, 'completed': step > 1 }">
        <span data-translate>Configura</span>
      </li>
      <li ui-sref="secondStep wizard" :class="{ 'active': step == 2, 'completed': step > 2 }">
        <span data-translate>Paga</span>
      </li>
      <li ui-sref="thirdStep wizard" :class="{ 'active': step == 3, 'completed': step > 3 }">
        <span data-translate>Disfruta</span>
      </li>
      
    </ul>
    
  </div>
</template>

<script>
export default {
 props: {
    step: null,
  },
  name: "BreadCrumbs",
  data: () => ({
  }),
  computed: {
 
  },
  created() {

  }
};
</script>
<style scoped>
ul.wizard,
ul.wizard li {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 60%;
}

ul.wizard {
  counter-reset: num;
}

ul.wizard li {
  flex-direction: column;
  align-items: center;
  position: relative;
}


/* Cerchio*/

ul.wizard li::before {
  counter-increment: num;
  content: counter(num);
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  line-height: 1.5em;
  border-radius: 50%;
  font-size: 1.5em;
  background: #c1c1c1;
  cursor: pointer;
  color: white;
  font-weight: 600;
}


/* Linea */

ul.wizard li ~ li::after {
  content: '';
  position: absolute;
  width: 100%;
  right: 50%;
  height: 4px;
  background-color: #c1c1c1;
  font-size: 1.5em;
  top: calc(0.75em - 2px);
  z-index: -1;
}


/* Tutte le righe che vengono dopo l'ultimo completed */

ul.wizard li.completed ~ li::after {
  content: '';
  position: absolute;
  width: 100%;
  right: 50%;
  height: 4px;
  background-color: #c1c1c1;
  top: calc(0.75em - 2px);
  z-index: -1;
}

ul.wizard li.active::before {
  background: #45A1E3;
  color: white;
  font-size: 1.5em;
  font-weight: 600;
}

ul.wizard li.active::after {
  background: #45A1E3;
  color: white;
}

ul.wizard span {
  margin-top: 2%;
  color: #333;
  font-size: .9em;
  font-weight: 700;
  color: #575757;
  
  word-break: break-all;
}


/*  updated sample  */


/*  number and circle  */

ul.wizard li.completed::before {
  background: #68DAA4;
  color: white;
  font-size: 1.5em;
  font-weight: 600;
}

ul.wizard li.completed span {
  /*  text  */
  color: #575757;
}

ul.wizard li.completed + li::after {
  /*  line after circle  */
  
  background: #68DAA4;
}

ul.wizard li.completed::after {
  /*  line before circle  */
  background: #68DAA4;
}

</style>
