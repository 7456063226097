<template>
  <div>
    <div>
      <Header></Header>
    </div>

  <div class="espaciado" fluid="sm">
<b-card-group>
    <div
      class="cardPrincipal"
      
    >
    <p style="font-weight: 700; color: #0069A9; font-size: 2.1vh;">Olvidé mi contraseña</p>
      <div class="p-3">
        <p>
          Para restablecer su contraseña, ingrese el nombre de usuario o la dirección de correo electrónico que utiliza.
          Restablecer su contraseña lo cerrará en todas las sesiones.
        </p>
        <b-form @submit="onSubmit" @reset="onReset">
          <b-form-group
            label="nombre de usuario."
            label-for="input-usuario"
          >
            <b-form-input
              id="input-usuario"
              v-model="usuario"
              type="text"
              required
              style="width: 40%;"
            ></b-form-input>
          </b-form-group>
  
         <div class="d-flex justify-content-center">
          <b-button class="mr-5" type="submit" variant="outline-primary-custom" :disabled="btnContinuar">
            <span v-show="!btnContinuar">Continuar</span>
  
            <b-spinner v-show="btnContinuar" small></b-spinner>
            <span v-show="btnContinuar"> Cargando...</span>
          </b-button>
          <b-button v-on:click="onReset" variant="outline-secondary">Atrás</b-button>
         </div>
        </b-form>
      </div>
    </div>
</b-card-group>
  </div>
    <div>
      <footer id="footerLogin">
      </footer>
    </div>
  </div>
</template>

<script>
import Header from "@/components/control-acceso/Header";
export default {
  name: "ForgottenPassword",
  components: {Header},
  data() {
    return {
      usuario: '',
      btnContinuar: false,
      mensajeAPI: '',
      tipoSistema: Number
    }
  },
  created() {
    this.tipoSistema = Number(this.$idSistema);
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()

      this.btnContinuar = !this.btnContinuar

      this.enviarCorreoContraseniaAPI();
    },
    onReset(event) {
      event.preventDefault()
      this.$router.push({ name: "Login" })
    },
    enviarCorreoContraseniaAPI(){
      const parametros = {nombreUsuario: this.usuario, idSistema: this.tipoSistema}
      this.$http
          .post(this.$apiControlAcceso +
              "recuperarContrasenia", parametros
          )
          .then((respuesta) => {
            this.btnContinuar = !this.btnContinuar
            if(respuesta.data.data.codigo === 200){
              if(!respuesta.data.data.error){
                this.$swal.fire({
                  icon: "success",
                  title: respuesta.data.data.mensaje,
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push({ name: "Login" })
                  }
                })
              }else{
                this.$swal.fire({
                  icon: "warning",
                  title: respuesta.data.data.mensaje,
                });
              }
            }else{
              this.$swal.fire({
                icon: "error",
                title: respuesta.data.data.mensaje,
              });
            }
            this.btnContinuar = false;
          })
    }
  },
}
</script>

<style scoped>
.espaciado {
  margin: 6%;
}

.card-header:first-child {
  background-color: #0e6ba9 !important;
}

.cardPrincipal{
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.205);
        margin: 2%;
  padding: 0.5% 1% 0.5% 1%;
  border-radius: 5px;
    }
</style>
