<template>
  <div>
    <PortalVista :stepBread="3">
      <template #content>
        <div
            v-if="carritoLista === undefined || carritoLista.length === 0"
            class=" text-center"
        >
          <notaCarritoVacio/>
        </div>
        <div v-else>
          <p class="alert-info alert">
            Llena los siguientes campos con la información de tu tarjeta de
            crédito o débito, y tal como aparecen en tu
            <strong>Estado de Cuenta</strong>.
          </p>
            <b-alert show variant="warning" style="text-align: center;">Mantenga abierta esta ventana, después de realizar el pago.</b-alert>
          <b-row>
            <b-col>
              <FormularioPagoMultiva/>
            </b-col>
          </b-row>
        </div>
      </template>
      <template #detaills>
        <ResumenCompra :pago="false" :total="total"></ResumenCompra>
      </template>
    </PortalVista>
    <pie :login="false"/>
  </div>
</template>

<script>
import notaCarritoVacio from "../components/NotaCarritoVacio.vue";
import FormularioPagoMultiva from "../components/FormularioPagoMultiva.vue";
import pie from "../components/Footer.vue";
import ResumenCompra from "../components/ResumenCompra";
import PortalVista from "../components/PortalVista";

export default {
  name: "CarritoPago",
  components: {
    PortalVista,
    ResumenCompra,
    notaCarritoVacio,
    FormularioPagoMultiva,
    pie,
  },
  data: () => ({
    cargando: true,
    carritoLista: [],
    total: 0,
  }),
  created() {
    this.traerCarrito();
    this.total = this.$session.get("total")

  },
  methods: {
    cambiarTotal(total) {
      this.total = total;
      this.$session.set("total", total)
    },
    traerCarrito() {
      this.carritoLista = this.$session.get("carrito");
      this.carritoPacientesLista = this.$session.get("carritoPacientes");
      this.cargando = false;
    },
  },
};
</script>

<style>
</style>
