<template>

  <main>
    <NavBar/>
    <BreadCrumbs :step="stepBread" class="mt-5 mb-5"></BreadCrumbs>
    <b-container>
      <b-row>
        <b-col class="col-sm-12 col-md-9 col-lg-9">
          <b-card class="">
            <template #header>
              <h1 class="text-center">
                <slot name="header"/>
              </h1>
            </template>
            <b-card-body>
              <slot name="content"/>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col class="col-sm-12 col-md-3 col-lg-3 mt-2">
          <b-card class="">
            <template #header>
              Detalle
            </template>
            <b-card-body>
              <slot name="detaills"/>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import NavBar from "../views/Nav";
import BreadCrumbs from "./BreadCrumbs";

export default {
 props: {
    stepBread: null,
  },
  name: "PortalVista",
  components: {BreadCrumbs, NavBar},
  data: () => ({
    loading: false,
  }),

}
</script>

<style scoped>

</style>
