<template>
<div>
  <b-navbar toggleable="lg" type="dark" style="padding-top: 0; margin-top: 0; min-height:4em;background-color: white;  box-shadow: 0px 1px 5px 0px rgba(158,158,158,0.3);" class="colorBarra">
    <b-navbar-brand>
      <img width="10%" alt="Vue logo" src="../../assets/logo_dentalia.png" class="dentaliaLogo"/>
    </b-navbar-brand>
  </b-navbar>
</div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
</style>