<template>
  <PortalVista>
    <template #header>
      Selecciona algo
    </template>
    <template #content>
      <button
          v-for="estilo in botones" :key="botones.indexOf(estilo)"
          :class="'btn ' + estilo.elemento"
      >{{ estilo.nombre }}
      </button>
      <h1>titulo</h1>
      <h2>subtitulo</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad aperiam culpa illo non pariatur quo rem sapiente
        sequi temporibus vel! Autem et eum iste laudantium minima perspiciatis, quas recusandae voluptate?
      </p>
    </template>
  </PortalVista>
</template>

<script>
import PortalVista from "../components/PortalVista";

export default {
  name: "tema",
  components: {PortalVista},
  data: () => ({
    botones: []
  }),
  mounted() {
    console.log(this.$localStorageService.getColores())
    this.botones = this.$localStorageService.getColores()
        .filter(e => e.elemento.includes('btn'))
        .filter(e => !e.elemento.includes('text'))
    ;
    //this.$root.$emit('step', 1);
    this.$root.$emit('loading', false);
  }
}
</script>

<style scoped>

</style>