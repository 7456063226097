import VueJwtDecode from 'vue-jwt-decode';
const LocalStorageService = (function () {

    function _getAccessToken() {
        const sesionK = sessionStorage.getItem("vue-session-key");
        if (sesionK !== undefined && sesionK !== null) {
            return JSON.parse(sesionK).token;
        }
        return null;
    }

    function obtenerValorToken() {
        const sesionK = sessionStorage.getItem("vue-session-key");
        if (sesionK !== undefined && sesionK !== null) {
            const tokenCad = JSON.parse(sesionK).token;
            const tokenCadSimple = tokenCad.replace("Bearer ", "");
            // console.log("VueJwtDecode.decode(tokenCadSimple)")
            // console.log(VueJwtDecode.decode(tokenCadSimple))
            return VueJwtDecode.decode(tokenCadSimple);
        }
        return null;
    }

    function _getIdUsuarioToken() {
        const tokenValor = obtenerValorToken();
        return tokenValor.idUsuario;
    }

    function _asignarUsuarioAutenticado(usuario) {
        sessionStorage.setItem('usuario', JSON.stringify(usuario))
    }

    function _obtenerUsuarioAutenticado() {
        return JSON.parse(sessionStorage.getItem("usuario"));
    }



    function _obtenerRefreshToken() {
            return sessionStorage.getItem("refres_token");
    }

    function _getIdEmpresa() {
        const usuarioAutenticado = _obtenerUsuarioAutenticado();
        return usuarioAutenticado.idEmpresa;
    }

    function _getError() {
        const usuarioAutenticado = _obtenerUsuarioAutenticado();
        return usuarioAutenticado.errorExistente;
    }

    function _getTipoError() {
        const usuarioAutenticado = _obtenerUsuarioAutenticado();
        return usuarioAutenticado.errorTipo;
    }

    function _getLogo() {
        const usuarioAutenticado = _obtenerUsuarioAutenticado();
        return usuarioAutenticado.logo;
    }

    function _getMsi() {
        const usuarioAutenticado = _obtenerUsuarioAutenticado();
        return usuarioAutenticado.portalMsi;
    }

    function _getColores() {
        const usuarioAutenticado = _obtenerUsuarioAutenticado();
        return usuarioAutenticado.colores;
    }

    function _getIdUsuario() {
        const usuarioAutenticado = _obtenerUsuarioAutenticado();
        return usuarioAutenticado.idUsuario;
    }

    function _asignarIdUsuarioAutenticado(idUsuario) {
        sessionStorage.setItem('id_usuario', JSON.stringify(idUsuario));
    }

    function _obtenerIdUsuarioAutenticado() {
        return JSON.parse(sessionStorage.getItem("id_usuario"));
    }


    return {
        getAccessToken: _getAccessToken,
        getIdEmpresa: _getIdEmpresa,
        getError: _getError,
        getTipoError: _getTipoError,
        getLogo: _getLogo,
        getColores: _getColores,
        getMsi: _getMsi,
        asignarUsuarioAutenticado: _asignarUsuarioAutenticado,
        obtenerRefreshToken: _obtenerRefreshToken,
        getIdUsuario: _getIdUsuario,
        asignarIdUsuarioAutenticado: _asignarIdUsuarioAutenticado,
        obtenerIdUsuarioAutenticado: _obtenerIdUsuarioAutenticado,
        getIdUsuarioToken:_getIdUsuarioToken
    }


})();
export default LocalStorageService;
