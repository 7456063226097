<template>
  <div>
    <BarraNavegacion :membresias="membresias"/>
      <div class="p-0" style="background-color: white; border: 1px solid #EFEFEF; margin: 2% 5% 2% 5%; border-radius: 20px; min-height: 80vh;">
          <b-row class="m-0 p-0" style="">
              <b-col class="col m-0 p-0" style="">
                <div v-if="windowWidth > 768" class="d-flex flex-row p-0 pl-5 wizardCompleto" style="min-height: 55px; border-bottom: .01vh solid #EFEFEF;" >
                      <div class="pr-4" v-for="(option, index) in wizard" v-bind:key="index">
                          <div class="d-flex justify-content-center align-items-center" :class="{'lineaActiva': index + 1 == 3}" style="min-height: 55px;">
                              <div class="d-flex justify-content-between" style="width: 140px;">
                                  <div class="d-flex justify-content-center align-items-center circuloWizardActivo">{{ index+1 }}</div>
                                  <p class="m-0 p-0 txtWizardActivo">{{option}}</p>
                                  <em class="chevron_right mt-2 "></em>
                              </div>
                          </div>
          
                      </div>
                  </div>
                  
                  <div v-if="windowWidth <= 768">
                     <b-row class="p-0 m-0" style="border-bottom: 1px solid #EFEFEF;">
                      <b-col cols="6" style="border-right: 1px solid #EFEFEF;" >
                          <div id="info-wizard" class="d-flex justify-content-start align-items-center lineaActiva"  style="min-height: 55px; max-height: 100px;  width: 60%;">
                              <div class="d-flex justify-content-between" style="width: 140px;">
                                  <div class="d-flex justify-content-center align-items-center circuloWizardActivo mr-2">
                                      <p class="p-0 m-0" style="font-size: .8em; color: white">3/3</p>
                                  </div>
                                  <p class="m-0 p-0 txtWizardActivo" >{{wizard[2]}} </p>
                                  <em class="chevron_down "></em>
                              </div>
                          </div>
                      </b-col>
                      <b-col cols="6">
                          <div class="d-flex justify-content-center align-items-center" style="min-height: 50px; max-height: 100px;">
                              <em class="carrito mt-1 mr-2"></em>
                              <p v-if="finCompra == false && precioTotal != undefined" class="m-0 txtWizardActivo" style="font-size: 1em; font-weight: 500;">{{ precioTotal | moneda }}</p>
                          </div>
                      </b-col>
                     </b-row>
                          
                     <b-popover target="info-wizard" variant="light" triggers="hover blur" @show="onPopoverShow" @hidden="onPopoverHide">
                          <div class="d-flex justify-content-start align-items-center mt-2" v-for="(option, index) in wizard" v-bind:key="index" style="min-height: 30px;">
                              <div class="d-flex justify-content-center align-items-center circuloWizardActivo"  style="height: 20px !important; width: 20px !important;">{{ index+1 }}</div>
                              <p class="m-0 p-0 ml-2 txtWizardActivo">{{option}} </p>      
                          </div>
                     </b-popover>
                     

                     <div class="pl-5 pt-4 pr-5">
                      <p style="font-weight: 600; color: #0069A9; font-size: .9em;">Resumen del carrito</p>
                      <p style="font-size: .8em;">Por favor seleccione cada plan para configurar los pacientes correspondientes.</p>
                      <b-form-select v-if="listaPacienteCertificados.planesInformacion" size="sm" v-model="planSeleccionado" :options="optionsWithIndex" value-field="index"  
                      text-field="name">
                      </b-form-select>

                      <div class="mt-3">
                       
                          <p style="font-weight: 500; font-size: .8em;">Pacientes del plan:</p>
                          <div v-if="listaPacienteCertificados">
                            <div v-for="(paciente, index) in listaPacienteCertificados.planesInformacion[planSeleccionado].certificadosPacientes" v-bind:key="index" style="font-size: .8em;">
                              <ul>
                                  <li>
                                      <p>{{ paciente.nombrePaciente }}</p>
                                  </li>
                                  
                              </ul>
                             
                          </div>
                          </div>
                      </div>
                     </div>

                     

                  </div>
                  
                  <div class="pl-5 pt-4 pr-5 scrollable-container no-horizontal-scroll">
                      <p style="font-weight: 600; color: #0069A9; font-size: 1em;">¡Gracias por tu compra!</p>
                      <p style="font-size: .9em;">
                          Se ha enviado un correo a la dirección que se registró como contacto
                          principal (<strong>{{
                          listaPacienteCertificados.correoTitular
                          }}
                          <span v-if="loader" style="color:var(--text-subtitulos) ">
                              <b-spinner small></b-spinner>
                              Cargando...
                          </span>
                      </strong>). En él encontrará información importante
                          <strong>relacionada con tu compra </strong> y podrás
                          <strong>descargar el certificado</strong> de cada uno de tus
                          planes adquiridos.
                      </p>

                         <div class="d-flex justify-content-end mt-5" v-if="listaPacienteCertificados.planesInformacion != null">
                          <b-button
                              style="min-width: 90px;"
                              size="sm"
                              variant="outline-primary"
                              class="btn-outline-primary-custom"
                              @click="irFacturacion()">Facturar
                          </b-button>
                         </div>

                         <div>
                          <p class="text-subtitulos">Certificados por plan:</p>
                          <b-alert show variant="warning" v-if="!loader && listaPacienteCertificados.planesInformacion == null" class="text-center mt-4"><strong>No hay Certificados</strong></b-alert>
                          <div class="pl-1 pr-4 pt-1" v-if="listaPacienteCertificados.planesInformacion != null">
                            <div class="row">
                              <div class="col-sm-12 col-md-6 col-lg-3">
                                <div class="image-container image-wrapper">
                                  <img
                                    class="plan-image"
                                    :src="infoPlan.imagenPlan"
                                    alt=""
                                  />
                                </div>
                              </div>
                        
                              <div class="col-sm-12 col-md-6 col-lg-8" style="font-size: 14px">
                                <div class="d-flex justify-content-between" style="width: 111%;">
                                  <p class="titulo-plan">{{ infoPlan.nombrePlan }}</p>
                                  <b-button
                                    v-b-tooltip.hover
                                    title="Descargar todos los certificados"
                                    class="boton-agenda-inv"
                                    style=" width: 30px; height: 30px; margin-top: -1%"
                                    variant="primary"
                                    @click="guardarCertificados()"
                                  >
                                    <em
                                      style="height: 20px;"
                                      class="download_all_svg icon-small"
                                    ></em>
                                  </b-button>
                                </div>
                                
                                <p>
                                  <strong> Vigencia: </strong>
                                  {{ infoPlan.vigenciaInicio | formatoFecha }} al
                                  {{ infoPlan.vigenciaFin | formatoFecha }}
                                </p>
                                <p>
                                  <strong>Tipo plan:</strong>
                                  {{ infoPlan.tipoValidacion }}
                                </p>
                                <p>
                                  <strong>Personas incluidas:</strong>
                                  {{ infoPlan.numeroMaximo }}
                                </p>
                              </div>
                            </div>
                            
                            <div v-for="(paciente, index) in listaPacienteCertificados.planesInformacion[planSeleccionado].certificadosPacientes" :key="index + 1" class="mt-5">
                                <b-row class="p-0 m-0 mr-4 linePaciente"  style="height: 30px">
                                  <b-col>
                                      <div class="d-flex">
                                          <p class="mr-1">{{ index + 1 }}.</p>
                                          <p class="textoSemibold">
                                            {{paciente.nombrePaciente}}
                                          </p>
                                      </div>
                                  </b-col>
                                  <b-col cols="1">
                                      <div class="col-1 ml-3">
                                        <em @click="downloadBase64File(paciente.certificadoPdf, paciente.nombrePaciente)"
                                          v-b-tooltip.hover.leftbottom
                                          title="Descargar certificado"
                                          class="download_svg icon-boton-agenda-1 icon-small iconos-botones"
                                          style="margin-top: 18%; margin-left: 4%">
                                        </em>
                                      </div>
                                  </b-col>
                                </b-row>
                          </div>
              </div>
                         </div>

                         <div style="font-size: .9em; margin-top: 10%;">
                          <p class="mt-4 mb-4">
                            A partir de este momento, ya puedes aprovechar los beneficios de tus planes dentales en cualquiera de nuestras clínicas. Ya contamos con
                            sus datos registrados en sistema, sólo tiene que presentar su credencial para votar o alguna otra identificación con fotografía.
                          </p>
                         </div>
                         <div class="mt-3" style="font-size: .9em;">
                          <p style="font-size: 1.1em !important; color: #F98553; font-weight: 500;">Estimado usuario</p>
                          <p>En caso de <strong>NO recibir sus certificados en el correo de confirmación de la compra de su plan</strong>, 
                            por favor envíe un correo a la dirección ventas@dentalia.com con la siguiente información.
                          </p>
                          <ol>
                            <li>Nombre completo del titular</li>
                            <li>Fecha de nacimiento del titular</li>
                            <li>Fecha de nacimiento del dependiente</li>
                            <li>Fecha de compra</li>
                            <li>Tipo de plan</li>
                          </ol>
                         </div>

                         <div class="mt-3 mb-4" style="text-align: center" v-if="finCompra">
                          <b-button v-if="!membresias" @click="irInicio" variant="primary" class="botones"
                            >Ir a inicio</b-button
                          >
                      </div>
                  </div>
              </b-col>
              <b-col cols="4" v-if="windowWidth > 768" class="m-0 p-0" style="border-left: 1px solid #EFEFEF; min-height: 80vh;">
                  <div class="d-flex align-items-center" style="min-height: 55px; border-bottom: .01vh solid #EFEFEF;">
                     
                  </div>
                  <ListadoPlanes  :carritoPlanes="listaPacienteCertificados.planesInformacion"  :finCompra="finCompra" @clicBoton = "clicBoton" @seleccionarPlan = "seleccionarPlan" :windowWidth=" windowWidth"/>
              </b-col>    
          </b-row>        
      </div>
     
  </div>
</template>

<script>
import BarraNavegacion from "../../views/nuevo_portal/BarraNavegacion";
import ListadoPlanes from "./ListadoPlanes.vue"
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
export default {
  name: "FinCompra",
  components: {
    BarraNavegacion,ListadoPlanes
  },
  data: () => ({
    infoPlan: null,
    planSeleccionado: 0,
      finCompra: true,
      wizard: [
          "Configura",
          "Paga",
          "Disfruta"
      ],
    carrito: [],
    carritoPlanes: [],
    titulos: [
      { key: "Seleccion", label: "" }, // Agrega una columna vacía para la selección
      { key: "nombrePlan", label: "Nombre plan" },
      { key: "tipoPlan", label: "Tipo plan" },
      'no',
      
    ],
    membresias: false,
    windowWidth: window.innerWidth,
    token: null,
    listaPacienteCertificados: {},
    loader:true

  }),
  created() {
      this.carritoPlanes = this.$session.get("carrito");
      if(this.carritoPlanes[0].membresias && this.carritoPlanes[0].membresias == true){ 
        this.membresias = true;
      }
      
      this.token = this.$route.params.token.split("/*");
      this.obtenerCertificados();
    
  },
  computed: {
  optionsWithIndex() {
    return this.listaPacienteCertificados.planesInformacion.map((item, index) => {
      return {
        index: index,
        name: item.nombrePlan
      };
    });
  },
},
  mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
      };
    },
    watch:{
      planSeleccionado: function (val) {
        this.infoPlan = this.carritoPlanes.find(plan => this.listaPacienteCertificados.planesInformacion[val].idPlan == plan.idPlan);
      }
    },
  methods: {
   
    onPopoverShow() {
          this.segundaOpcionAbierto = true;
          },
      onPopoverHide() {
          this.segundaOpcionAbierto = false;
      },
    
    seleccionarPlan(planSeleccionado){
          this.planSeleccionado = planSeleccionado;
     
          this.infoPlan = this.carritoPlanes.find(plan => this.listaPacienteCertificados.planesInformacion[this.planSeleccionado].idPlan == plan.idPlan);
        
      },
      clicBoton(){
       
          this.$emit("clicFormulario")
      },
    guardarCertificados() {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );

      if (isMobile) {
        this.listaPacienteCertificados.planesInformacion.forEach(planes => {
          planes.certificadosPacientes.forEach(
          (item, index) => {
            const { certificadoPdf, idPaciente } = item;
            const nombreArchivo = `certificado_${idPaciente}_${index + 1}.pdf`;

            const pdfBlob = atob(certificadoPdf);
            const arrayBuffer = new ArrayBuffer(pdfBlob.length);
            const uint8Array = new Uint8Array(arrayBuffer);

            for (let i = 0; i < pdfBlob.length; i++) {
              uint8Array[i] = pdfBlob.charCodeAt(i);
            }

            const pdfBlobObject = new Blob([arrayBuffer], {
              type: "application/pdf",
            });

            saveAs(pdfBlobObject, nombreArchivo);
          }
        );
        });
      
        this.$swal({
              icon: "success",
              title: "Aviso",
              text: "Se descargó el archivo correctamente",
              showConfirmButton: false,
              timer: 2500,
            });
      } else {
        const zip = new JSZip();
        this.listaPacienteCertificados.planesInformacion.forEach(planes => {
          planes.certificadosPacientes.forEach(
          (item, index) => {
            const { certificadoPdf, nombrePaciente } = item;
            const nombreArchivo = `certificado_${nombrePaciente}_${
              index + 1
            }.pdf`;
            zip.file(nombreArchivo, certificadoPdf, { base64: true });
          }
        );
        });

        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, "certificados.zip");
        });

        this.$swal({
              icon: "success",
              title: "Aviso",
              text: "Se descargó el archivo correctamente",
              showConfirmButton: false,
              timer: 2500,
            });
      }
    },
    irInicio() {
      this.$session.set("carrito", []);
      this.$router.push({ name: "Inicio" });
    },
    onRowSelected(items) {
      this.selected = items;
        
        const indice = this.listaPacienteCertificados.planesInformacion.findIndex(
          (plan) => plan === this.selected[0]
        );

      this.planSeleccionado = indice;

    },
    obtenerCertificados() {
      this.loader = true;
      
      this.$http
        .post(this.$api + "stripe/certificado/obtenerIdTicket", this.token)
        .then((respuesta) => {
          this.listaPacienteCertificados = respuesta.data.data;
         

            this.infoPlan = this.carritoPlanes.find(plan => this.listaPacienteCertificados.planesInformacion[this.planSeleccionado].idPlan == plan.idPlan);
    
        })
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "Aviso",
            text: "Ocurrio un error al realizar e intente nuevamente",
          });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    downloadBase64File(base64Data, fileName) {
      const linkSource = `data:application/pdf;base64,${base64Data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      this.loaderDescargar = false;

      this.$swal({
              icon: "success",
              title: "Aviso",
              text: "Se descargó el archivo correctamente",
              showConfirmButton: false,
              timer: 2500,
            });
    },
    irFacturacion() {
      this.$router.push({ name: "Facturacion" });
      this.$session.set("idTicket", this.listaPacienteCertificados.idTicket);
    },
  },
};
</script>

<style scoped>
 .scrollable-container {
  overflow-y: auto; 
  max-height: 82vh; 
}
.linePaciente{
      border-bottom: 1px solid #D6E7F3;
  }
.circuloWizardInactivo{
      color: white;
      background-color: #C2C2C2;
      border-radius: 50%;
      width: 24px;
      height: auto;
  }

  .circuloWizardActivo{
      color: white;
      background-color: #4B94C2;
      border-radius: 50%;
      width: 24px;
      height: auto;
  }

  .txtWizardInactivo{
      color: #C2C2C2;
      font-weight: 500;
  }

  .txtWizardActivo{
      color: #363F55;
      font-weight: 500;
  }

  .lineaActiva{
      border-bottom: 2px solid #4B94C2;
  }

.cardPrincipal {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.205);
  margin: 2%;
  padding: 0.5% 1% 0.5% 1%;
  border-radius: 5px;
}

.boton-agenda-inv {
  border-radius: 50rem;
  color: var(--color-icono);
  background-color: white;
  border-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.223);
  padding: 1%;
}

.boton-agenda-inv:active {
  border-color: var(--color-icono);
}
/**Inicio Loader*/
.loader {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: var(--text-subtitulos)  var(--text-subtitulos)  transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #CCC #CCC;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #CCC #CCC transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
/**Fin Loader*/
.contenedor-loader{
  width:100%; height: 100%;
  background-color: rgb(141 198 232 / 30%);
  position: fixed;
}

.loader-div{
  top: 30%;
  left: 45%;
  position: absolute;
  color:white;
  text-align:center;
  font-family: 'Lucida Console', Courier, monospace;
  color: var(--text-subtitulos);
}
@media only screen and (max-width: 900px) {
  .loader-div{
    top: 3vh;
    left: 1vw;
  }
}
</style>

