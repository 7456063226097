<template>
  <div>
    <PortalVista>
      <template #header class="">
        <div>
          <p class="text-titulo">Planes disponibles</p>
        </div>
      </template>
      <template #content>
        <div class="noAlTope text-center">
          <b-spinner class="mr-1" v-if="loaderPlanes"></b-spinner>
        </div>
        <div
            v-if="planes.length == 0 && !loaderPlanes"
            class="noAlTope text-center"
        >
          <h2>No se encontraron planes disponibles para esta empresa.</h2>
          <p>
            Esto puede deberse a un error de configuración, por favor llámanos
            al 01.800.003.3682. ¡Gracias!
          </p>
        </div>
        <div v-else class="scrollable-container no-horizontal-scroll" style="min-height: 75vh;">
          <div class="row">
            <div
                class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xxl-5"
                v-for="datosPlanes in planes"
                :key="datosPlanes.idPlan"

            >
              <div class="plan-card" @click="seleccionarPlan(datosPlanes)">
                <div
                    class="image-container image-wrapper"
                    @mouseover="setHover(datosPlanes, true)"
                    @mouseout="setHover(datosPlanes, false)"
                    style="border-radius: 20px"
                >
                  <!-- <img :src="datosPlanes.imagen" alt="" /> -->

                  <img
                      class="plan-image"
                      :src="datosPlanes.imagenPlan"
                      alt=""
                  />

                  <div class="text-plan-hover">
                    <p class="text-hover-plan">
                      <strong> Vigencia del plan: </strong>
                      {{ datosPlanes.vigenciaInicio | formatoFecha }} al
                      {{ datosPlanes.vigenciaFin | formatoFecha }}
                    </p>
                    <p>
                      <strong>Tipo plan:</strong>
                      {{ datosPlanes.tipoValidacion }}
                    </p>
                    <p>
                      <strong>Personas incluidas:</strong>
                      {{ datosPlanes.numeroMaximo }}
                    </p>
                  </div>
                </div>
                <b-button
                    v-if="!datosPlanes.hover"
                    class="boton-agenda-inv"
                    style="
                    position: absolute;
                    top: 5px;
                    left: 25px;
                    width: 40px;
                    height: 40px;
                  "
                    variant="primary"
                >
                  <div style="margin-top: 18%; margin-left: 4%" class="icon-small">

                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                         xmlns:svgjs="http://svgjs.com/svgjs" width="22" height="22" x="0" y="0" viewBox="0 0 512 512"
                         style="enable-background:new 0 0 512 512" xml:space="preserve" class="iconos-botones">
                      <g>
                        <path
                            d="M508.177 245.995C503.607 240.897 393.682 121 256 121S8.394 240.897 3.823 245.995a15.002 15.002 0 0 0 0 20.01C8.394 271.103 118.32 391 256 391s247.606-119.897 252.177-124.995a15.004 15.004 0 0 0 0-20.01zM256 361c-57.891 0-105-47.109-105-105s47.109-105 105-105 105 47.109 105 105-47.109 105-105 105z"
                            fill="#000000" data-original="#000000" class="iconos-botones"></path>
                        <path
                            d="M271 226c0-15.09 7.491-28.365 18.887-36.53C279.661 184.235 268.255 181 256 181c-41.353 0-75 33.647-75 75s33.647 75 75 75c37.024 0 67.668-27.034 73.722-62.358C299.516 278.367 271 255.522 271 226z"
                            fill="#000000" data-original="#000000" class="iconos-botones"></path>
                      </g>
                    </svg>
                  </div>
                </b-button>

                <div class="name-container">
                  <b-button
                      class="boton-agenda-inv boton-carrito"
                      @click="agregarCarrito(datosPlanes)"
                      variant="primary"
                  >
                    <div style="margin-top: 18%; margin-left: 4%" class="icon-small">
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="22" height="22" x="0" y="0" viewBox="0 0 32 32" style="enable-background:new 0 0 512 512" xml:space="preserve" class="iconos-botones">
                        <g>
                          <path d="M30.352 6.137A2.987 2.987 0 0 0 28 5H7.733l-.759-3.229A1 1 0 0 0 6 1H2a1 1 0 0 0 0 2h3.208l3.535 15.026A2.992 2.992 0 0 0 9 24h17a1 1 0 0 0 0-2H9a1 1 0 0 1 0-2h16.882a2.99 2.99 0 0 0 2.92-2.313l2.119-9a2.986 2.986 0 0 0-.569-2.55z" fill="#000000" data-original="#000000" class="iconos-botones"></path>
                          <circle cx="23" cy="28" r="3" fill="#000000" data-original="#000000" class="iconos-botones"></circle>
                          <circle cx="12" cy="28" r="3" fill="#000000" data-original="#000000" class="iconos-botones"></circle>
                        </g>
                      </svg>
                    </div>
                  </b-button>
                  <p class="text-titulo-card">{{ datosPlanes.nombrePlan }}</p>
                  <b-row class="plan-info">
                    <b-col cols="7">
                      <p>Precio individual (con IVA):</p>
                    </b-col>
                    <b-col cols="4">
                      <p>{{ datosPlanes.precio | moneda }}</p>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #headerDetalle class="">
        <p class="text-subtitulos-detalle" v-if="planSeleccionado">
          Beneficios del plan {{ planSeleccionado.nombrePlan }}
        </p>
      </template>
      <template #detallePlan>
        <div v-if="planSeleccionado">
          <img :style="{ transform: isZoomed ? 'scale(1.5)' : 'scale(1)' }" style="width: 100%; height: auto" @click="toggleZoom" :src="planSeleccionado.imagen" />
      
        </div>
      </template>
    </PortalVista>
  </div>
</template>

<script>
import PortalVista from "../../components/nuevo_portal/VistaPlanes";

export default {
  name: "Planes",
  components: {
    PortalVista,
  },
  data: () => ({
    isZoomed: false,
    nameTopOffset: 50,
    planes: [],
    cantidadCarrito: 0,
    bounceInOption: {
      classes: "bounceIn",
      duration: 600,
    },
    cargando: true,
    url: null,
    loaderPlanes: false,
    planSeleccionado: null,
    imagenes: null,
  }),

  created() {
    this.traerPlanes();
    if (this.$session.has("cantidadCarrito")) {
      this.cantidadCarrito = this.$session.get("cantidadCarrito");
    }
    this.$session.set("carrito", []);
    this.$session.set("pacientes", []);
    this.$session.set("pagoRecurrente", null);
    this.$session.set("idMsiSeleccionado", null);
    this.$session.set("titular", null);
    this.$session.set("promocion", null);
    this.$session.set("referencia", null);
    this.$session.set("step", 0);
  },
  watch: {},
  methods: {
    toggleZoom() {
                    this.isZoomed = !this.isZoomed;
                },
    cargarImagenes() {
      const context = require.context(
          "@/assets/imagenes_planes",
          false,
          /\.(jpg|jpeg|png|gif|svg)$/
      );
      const imagenes = context.keys().map((imagenPath) => {
        const nombreImagen = imagenPath.match(
            /\/([^/]+)\.(jpg|jpeg|png|gif|svg)$/
        )[1];
        return {
          src: context(imagenPath),
          alt: nombreImagen,
        };
      });
      this.planes.forEach((plan, index) => {
        plan.imagenPlan = imagenes[index].src;
      });


    },
    /*  this.planes.forEach((plan) => {
        // Puedes definir el valor de la nueva propiedad aquí
        plan.imagenPlan = rutaImagen;
      }); */

    setHover(datosPlanes, value) {
      // Usa Vue.set para agregar la propiedad hover
      this.$set(datosPlanes, "hover", value);
    },
    seleccionarPlan(plan) {
      this.planSeleccionado = plan;

    },
    traerPlanes() {
      this.loaderPlanes = true;

      const url = this.$api + "plan/empresa"
      const datos = {
        idEmpresa: this.$localStorageService.getIdEmpresa()
      }

      this.$http
          .post(url, datos)
          .then((response) => {
            if (response.data.data) {
              response.data.data.forEach((i) => {
                i.imagen = this.convertirImagen(i.imagen);
              });
              this.planes = response.data.data;
              this.planSeleccionado = this.planes[0];
              this.cargarImagenes();
              this.loaderPlanes = false;
            }
          })
          .catch((err) => {
            this.errorTraerDatos(err);
            this.loaderPlanes = false;
          });
    },
    convertirImagen(datos) {
      let binary = "";
      let bytes = new Uint8Array(datos);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return "data:image/png;base64," + btoa(binary);
    },
    agregarCarrito(datos) {
      let carritoActivo = this.$session.get("carrito");
      
      if (carritoActivo) {
        const exist = carritoActivo.find(
            (inCart) => inCart.idPlan === datos.idPlan
        );
        console.log("exist",exist)
        if (exist) {
          exist.cantidad += 1;
          this.$session.set("carrito", carritoActivo);
          this.$bvToast.toast('El plan se agregó al carrito', {
                title: 'Carrito',
                noAutoHide: false,
                variant: 'success',
                solid: false
              })
        } else {
          //if(carritoActivo.length == 0){
          datos.cantidad = 1;
          
          datos.cardAbierta = false;
          console.log("datos",datos)
          carritoActivo.push(datos);
          this.$session.set("carrito", carritoActivo);
          this.$bvToast.toast('El plan se agregó al carrito', {
                title: 'Carrito',
                noAutoHide: false,
                variant: 'success',
                solid: false
              })
        }
        this.$root.$emit("carritonav");
      } else {
        carritoActivo = [];
        datos.cantidad = 1;
        carritoActivo.push(datos);
        this.$session.set("carrito", carritoActivo);

        this.$swal({
          icon: "info",
          title: "Has agregado un elemento al carrito",
          confirmButtonText: "OK",
        });
      }
    },
    abriImagen(imagen) {
      var win = window.open();
      win.document.write(
          '<iframe src="' +
          imagen +
          '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
      );
    },
  },
  notifications: {
    agregar: {
      type: "success",
      title: "AGREGASTE AL CARRITO:",
    },
    errorTraerDatos: {
      type: "error",
      title: "Error de servicio",
      message: "Error al traer los datos",
    },
  },
};
</script>
<style scoped>
.text-plan-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.57);
  color: white;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding: 10px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
  font-size: 1em;
}

.boton-carrito {
  position: relative;
  margin-top: -20px;
  left: 80%;
  width: 35px;
  height: 35px;
}


.plan-card {
  border: 1px solid #E3E3E3;
  border-radius: 20px;
  min-height: 12em;
  display: flex;
  flex-direction: column;
  text-align: flex-start;
  margin-bottom: 20%;
}

.image-container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 70%;
  overflow: hidden;
}

.image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
}


.image-container:hover .text-plan-hover {
  visibility: visible;
  opacity: 1;
}

.name-container {
  padding: 0% 3% 1% 8%;
}

.plan-card p.plan-name {
  text-align: left;
  color: #17a2b8;
  font-weight: 700;
  font-size: 1vw;
}

.plan-info {
  margin-top: -10px;
  text-align: left;
  color: #575757;
  font-weight: 600;
  font-size: 14px;
}

.boton-agenda-inv {
  border-radius: 50rem;
  color: var(--color-icono);
  background-color: white;
  border-color: #fff;
  box-shadow: 2px 3px 4px #c4c4c4;
  padding: 1%;
}

.boton-agenda-inv:active {
  border-color: var(--color-icono);
}


.icon-small {
  font-size: 10%;
}
</style>
