<template>
  <div class="">
    <b-navbar toggleable="lg" type="dark" class="nav sombra">
      <validacionSession></validacionSession>
      <b-navbar-brand href="#">
        <div  v-if="logo !== 'data:image/png;base64,'">
        <img :src="logo" class="logo-empresa" alt="Empresa"/>
        </div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/planes" class="titulos">Planes Dentales</b-nav-item>
          <b-nav-item to="/carrito" href="#" class="titulos">
            <b-icon-cart></b-icon-cart>
            ({{ cantidadCarrito }})
          </b-nav-item>
          <!--<b-nav-item to="/facturacion" href="#" class="titulos">
            <b-icon-file/>
            Facturar
          </b-nav-item>-->
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-on:click="logout()" href="#" class="titulos">Cerrar Sesión</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item href="#" disabled>
            <b-img src="https://dentalia.com/wp-content/themes/dentalia/dist/images/dentalia_logo.png"
                   style="max-height: 3vw" alt="Dentalia"></b-img>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
import ValidacionSession from "@/components/control-acceso/ValidacionSession";
export default {
  name: "NavBar",
  components: {ValidacionSession},
  props: {
    mostrar: {
      require: true,
      type: Boolean,
    },
  },
  data: () => ({
    logo: null,
    cantidadCarrito: 0,
  }),
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push("/");
    }
  },
  created() {

    this.logo = this.convertirImagen(this.$localStorageService.getLogo());

    let style = document.documentElement.style;
    this.$localStorageService.getColores().forEach(colores => {
      style.setProperty('--' + colores.elemento, colores.color);
    })
    this.carrito();
  },
  mounted() {
    this.$root.$on('carritonav', () => {
      this.carrito();
    });
  },
  methods: {
    logout: function () {
      this.terminarControlAcceso();
      localStorage.clear();
      this.$session.destroy();
      this.$router.push("/");
    },
    carrito() {

      const carrito = this.$session.get('carrito');
      if (carrito && carrito.length > 0) {
        this.cantidadCarrito = carrito.map(plan => plan.cantidad).reduce((a, b) => a + b)
      } else {
        this.cantidadCarrito = 0;
      }

    },
    convertirImagen(datos) {
      let binary = "";
      let bytes = new Uint8Array(datos);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return "data:image/png;base64," + btoa(binary);
    },
    terminarControlAcceso() {
      let parametros = {
        idControlAcceso: this.$session.get("idControlAcceso")
      };
      const url = this.$apiControlAcceso + "cerrarSession/token";
      this.$http
          .post(url, parametros)
          .then((response) => {
            if (response.data.data) {
              console.log("termino con acceso de control");
            }
          })
          .catch((err) => {
            console.log(err);
          })
    },
  },
};
</script>

<style>
/* @import "../assets/css/portal.css";
 */
.logo-empresa {
  width: 100%;
  z-index: 9000;
  border-radius: 10px;
}

</style>
