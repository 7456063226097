import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueSession from 'vue-session'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotifications from 'vue-notifications'
import miniToastr from 'mini-toastr'
import axios from 'axios'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import VueMonthlyPicker from 'vue-monthly-picker'
import VueMoment from "vue-moment";
import moment from "moment";
import VAnimateCss from 'v-animate-css';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

/*estilos */
/* import './assets/css/estilos.css' */
import './assets/css/estilos_v2.css'

/*js */
import FuncionesAyuda from './services/FuncionesAyuda.js';
import LocalStorageService from "./services/LocalStorageService";
import Axios from "axios";
import CryptoJS from 'crypto-js';


import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.component("loading", Loading);


function toast({title, message, type, timeout, cb}) {
    return miniToastr[type](message, title, timeout, cb)
}

const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
}

const options = {
    success: toast,
    error: toast,
    info: toast,
    warn: toast
}

Vue.use(VueSession)
Vue.use(IconsPlugin)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2);
Vue.use(VueNotifications, options)
Vue.use(VueMoment);
Vue.use(VAnimateCss);

moment.locale('es');
Vue.use(VueMoment, {
    moment,
});

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.component('vue-monthly-picker', VueMonthlyPicker);

Vue.config.productionTip = false

miniToastr.init({types: toastTypes})

Vue.prototype.$http = axios;
Vue.prototype.$api = process.env.VUE_APP_BACK_API_BASE_URL;
Vue.prototype.$baseBack = process.env.VUE_APP_API_BASE_URL;
Vue.prototype.$baseBackControlAcceso = process.env.VUE_APP_API_BASE_URL_CONTROL_ACCESO;
Vue.prototype.$apiControlAcceso = process.env.VUE_APP_BACK_API_BASE_URL_CONTROL_ACCESO;
Vue.prototype.$idSistema = process.env.VUE_APP_ID_SISTEMA;
Vue.prototype.$baseURL = process.env.VUE_APP_FRONT_API_BASE_URL;
Vue.prototype.$baseFacturacion = process.env.VUE_APP_API_BASE_URL_FACTURACION;
Vue.prototype.$baseMembresiasUser = process.env.VUE_APP_ACCESS_MEMBRESIAS_USER;
Vue.prototype.$baseMembresiasPass = process.env.VUE_APP_ACCESS_MEMBRESIAS_DATO;
const keyMercado = process.env.VUE_APP_MERCADO_PAGO_KEY;

Vue.prototype.$keyMercado = keyMercado;


Vue.prototype.$global = FuncionesAyuda;
Vue.prototype.$moneda = FuncionesAyuda.funcionMoneda;
Vue.filter("moneda", FuncionesAyuda.funcionMoneda);
Vue.prototype.$formatoFecha = FuncionesAyuda.formatoFechaFuncion;
Vue.filter("formatoFecha", FuncionesAyuda.formatoFechaFuncion);


Vue.prototype.$onlyNumber = FuncionesAyuda.onlyNumber;
Vue.prototype.$localStorageService = LocalStorageService;


Axios.interceptors.request.use(
    request => {

        if (!request.url.includes("sugarondemand")) {
            const token = LocalStorageService.getAccessToken();
            if (token && !request.headers["Authorization"]) {
                request.headers["Authorization"] = token;
            }
        }

        if (!request.url.includes("/login") && request.data !== undefined && request.data !== null) {
            if (process.env.VUE_APP_SECURE_API !== undefined &&
                process.env.VUE_APP_SECURE_API === true || process.env.VUE_APP_SECURE_API === 'true') {
                var keyForCryptoJS = CryptoJS.enc.Base64.parse(btoa(process.env.VUE_APP_SECURE_KEY_API));

                request.headers["Content-Type"] = "application/json";
                request.data = CryptoJS.AES.encrypt(JSON.stringify(request.data), keyForCryptoJS, {
                    mode: CryptoJS.mode.ECB /* Override the defaults */
                    /*padding: CryptoJS.pad.Pkcs7 *//* PKCS#5 is a subset of PKCS#7, and */
                }).toString();
            }

            if (process.env.VUE_APP_DEBUG_API !== undefined &&
                process.env.VUE_APP_DEBUG_API === true || process.env.VUE_APP_DEBUG_API === 'true') {
                console.info("######### request", request.data);
            }
        }
        request.headers["Access-Control-Allow-Origin"] = '*';
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Axios.interceptors.response.use(
    response => {

        if (!response.config.url.includes("socket.io") && !response.config.url.includes("/login")
            && response.data !== undefined && response.data !== null) {

            const dataOrigen = response.data;
            try {

                if (process.env.VUE_APP_SECURE_API !== undefined &&
                    process.env.VUE_APP_SECURE_API === true || process.env.VUE_APP_SECURE_API === 'true') {
                    response.data = JSON.parse(desencriptar(response.data).toString(CryptoJS.enc.Utf8));
                }

                if (process.env.VUE_APP_DEBUG_API !== undefined &&
                    process.env.VUE_APP_DEBUG_API === true || process.env.VUE_APP_DEBUG_API === 'true') {
                    console.info("######### response", dataOrigen, response.data);
                }
            } catch (e) {
                console.error(e);
            }
        }
        return response;
    },
    (error) => {
        try {
            if (process.env.VUE_APP_SECURE_API !== undefined &&
                process.env.VUE_APP_SECURE_API === true || process.env.VUE_APP_SECURE_API === 'true') {
                error.response.data = JSON.parse(desencriptar(error.response.data).toString(CryptoJS.enc.Utf8));
            }
            return Promise.reject(error);
        } catch(e) {
            console.error(e);
            return Promise.reject(e);
        }
    }
);


const desencriptar = function(data) {
    const keyForCryptoJS = CryptoJS.enc.Base64.parse(btoa(process.env.VUE_APP_SECURE_KEY_API));
    const decodeBase64 = CryptoJS.enc.Base64.parse(data);

    const decryptedData = CryptoJS.AES.decrypt({ciphertext:decodeBase64}, keyForCryptoJS,
        {
            mode: CryptoJS.mode.ECB /* Override the defaults */
            /*padding: CryptoJS.pad.Pkcs7 *//* PKCS#5 is a subset of PKCS#7, and */
        }
    );

    return decryptedData;
}


const horasDiferenciaUtc = function () {
    const fecha = this.$moment(new Date()).format("Z")

    let arrayHora = fecha.split(':');

    return parseInt(arrayHora[0]);
}
Vue.prototype.$horas = horasDiferenciaUtc;
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
