<template>
    <PortalVista :stepBread="1">
      <template #header>
        <span class="d-flex text-titulo">Carrito de compras</span>
      </template>
      <template #content >
        <div class="scrollable-container no-horizontal-scroll" style="min-height: 75vh;">
          <div v-if="getPlanesCarrito.length === 0">
            <p class="text-subtitulos">Planes dentales seleccionados</p>
            <b-row class="d-flex justify-content-center">
              <notaCarritoVacio />
              <b-button variant="outline-primary-custom" size="md" @click="irPlanesDentales">Ver planes dentales</b-button>
            </b-row>
          </div>
          <div v-else>
            <div
              style="
                border-radius: 20px;
                border: 1px solid #D6E7F3;
                min-height: 77vh;
                padding-bottom: 0px
              "
              class="pl-4 pr-2"
            >
            <p class="text-subtitulos mt-3">Planes dentales seleccionados</p>
              <b-row
                v-for="(datosPlanes, index) in getPlanesCarrito"
                :key="datosPlanes.idPlan"
              >
               <b-col>
                <div class="row contenedorPlan" style="position: relative; min-height: 30vh;">
                <div class="col-sm-12 col-md-6 col-lg-3 mt-2 ">
                  <div class="image-container image-wrapper " style="justify-content: center !important;">
                    <img
                      class="plan-image"
                      :src="datosPlanes.imagenPlan"
                      alt=""
                    />
                  </div>
                  
                </div>
                <div class="col-sm-12 col-md-12 col-lg-9">
                  <div class="row">
                    <div class="col-sm-12 col-md-8 col-lg-8">
                      <p class="text-subtitulos">{{ datosPlanes.nombrePlan }}</p>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2">
                      <p style="font-weight: 600;">Precio unitario</p>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 ">
                      <p  style="font-weight: 600;">Total</p>
                    </div>
                  </div>
                  <div class="row" style="font-size: .9em;">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                      <p>
                        <strong> Vigencia: </strong>
                        {{ datosPlanes.vigenciaInicio | formatoFecha }} al
                        {{ datosPlanes.vigenciaFin | formatoFecha }}
                      </p>
                      <p>
                        <strong>Tipo plan:</strong>
                        {{ datosPlanes.tipoValidacion }}
                      </p>
                      <p>
                        <strong>Personas incluidas:</strong>
                        {{ datosPlanes.numeroMaximo }}
                      </p>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4 text-center">
                      <b-row class="d-flex justify-content-between w-100" style="border: 2px solid #d0d0d0; border-radius: 10px">
                        <b-col cols="4">
                          <b-button
                            size="sm"
                            :disabled="datosPlanes.cantidad === 1"
                            variant="light"
                            @click="cantidad(datosPlanes, -1)"
                          >
                            <b-icon-dash
                              style="stroke-width: 30px"
                            ></b-icon-dash>
                          </b-button>
                        </b-col>
                        <b-col
                          cols="4"
                         class="mt-1"
                          style="font-weight: 600"
                        >
                          {{ datosPlanes.cantidad }}
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            size="sm"
                            variant="ligth"
                         
                            @click="cantidad(datosPlanes, 1)"
                          >
                            <b-icon-plus  style="stroke-width: 30px"></b-icon-plus>
                          </b-button>
                        </b-col>
                        
                      </b-row>
                     <div class="justify-content-center">
                      <p
                      class="mt-4 mr-4 d-flex justify-content-center"
                      style="font-size: 80%; color: #0069aa"
                      @click="eliminarProducto(index)"
                    >
                      Eliminar
                    </p>
                     </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 ">
                     <div class="row">
                      <div class="col-6">
                        <p style="">{{ datosPlanes.precio | moneda }}</p>
                       
                      </div>
                      <div class="col-6">
                        <p style="font-weight: 600; font-size: 1em !important;"> {{
                          (datosPlanes.cantidad * datosPlanes.precio) | moneda
                        }}</p>
                      </div>
                     </div>
                    </div>
                  </div>  
                </div>
             
                </div>
                <div class="mr-3" style="bottom: -10%; position: absolute; width: 95%;">
                  <hr class=""/>
                  <div v-if="index == Object.keys(getPlanesCarrito).length -1" class="d-flex justify-content-end">
                    <p style="font-weight: 600;">Total </p>
                    <p class="ml-4 text-resaltado">{{total  | moneda}}</p>
                  </div>
                </div>
               </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <b-row>
       
        </b-row>
      </template>
      <template #headerDetalle class="">
        <div
          class="p-4"
          style="
            min-height: 90vh;
            background-color: white;
            border-radius: 20px;
            border: 1px solid #D6E7F3;
          "
        >
          <ResumenCompra :pago="false" />
        </div>
      </template>
    </PortalVista>
  </template>
  
  <script>
  import ResumenCompra from "../../components/nuevo_portal/ResumenCompra";
  import PortalVista from "../../components/nuevo_portal/VistaPlanes";
  import notaCarritoVacio from "../../components/NotaCarritoVacio.vue";
  
  export default {
    name: "Carrito",
    components: {
      PortalVista,
      ResumenCompra,
      notaCarritoVacio,
    },
    data: () => ({
      cargando: true,
      carritoPlanes: [],
      total: null,
      titulos: [
        { key: "cantidad", label: "Cantidad" },
        { key: "nombrePlan", label: "Descripción" },
        { key: "precio", label: "Precio unitario" },
        { key: "subTotal", label: "Subtotal" },
        { key: "accion", label: "" },
      ],
    }),
    created() {
      this.traerCarrito();
      this.$root.$emit("loading", false);
      this.$session.set("step", 1);
      this.calcular();
      this.$root.$on("carritonav", () => {
        this.calcular();
      });
    },
    computed: {
      getPlanesCarrito() {
        if (!this.carritoPlanes) return [];
        return this.carritoPlanes;
      },
    },
    methods: {
      irPlanesDentales(){
        this.$router.push({ name: "Planes" });
      },
      calcular() {
        const carrito = this.$session.get("carrito");
        if (carrito && carrito.length > 0) {
          this.total = this.$session
            .get("carrito")
            .map((plan) => plan.cantidad * plan.precio)
            .reduce((a, b) => a + b);
        } else {
          this.total = 0;
        }
      },
      cantidad(item, suma) {
        const carrito = this.$session.get("carrito");
        const plan = carrito.find((plan) => plan.idPlan === item.idPlan);
        plan.cantidad += suma;
        this.$session.set("carrito", carrito);
        this.$root.$emit("carritonav");
        this.traerCarrito();
      },
      traerCarrito() {
        this.carritoPlanes = this.$session.get("carrito");  
      },
  
      eliminarProducto(indice) {
        this.$swal({
          icon: "question",
          title: "¿Seguro deseas quitar el plan del carrito?",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
        }).then((aceptar) => {
          if (aceptar.value) {
            this.carritoPlanes.splice(indice, 1);
            this.envioVariablesCarrito();
            this.$forceUpdate();
          }
        });
      },
      envioVariablesCarrito() {
        this.$session.set("carrito", this.carritoPlanes);
        this.$root.$emit("carritonav");
      },
      guardarCarritoTemporal() {
        let lista = this.$session.get("carrito");
        
        const planes = lista.map((plan) => {
          plan.pacientes = [...Array(plan.numeroMaximo).keys()].map(() => ({}));
          return plan;
        });
        this.$session.set(
          "carrito",
          planes
            .map((plan) => {
              return [...Array(plan.cantidad).keys()].map(() => ({
                ...plan,
                cantidad: 1,
              }));
            })
            .reduce((a, b) => a.concat(b))
        );
        this.$root.$emit("carritonav");
        this.$router.push({ name: "CarritoPaciente" });
      },
    },
  };
  </script>
  
  <style scoped>
  @media only screen and (max-width: 600px) {
    .letraResposivaTitulo {
      font-size: 6vw !important;
    }
  }

  @media (max-width:900px){
    .contenedorPlan{   
      padding: 1%;
    }
  }

  @media (min-width:901px){
    .contenedorPlan{   
      margin-top: 1%;
      margin-bottom: 0px;
    }
  }
  
  .plan-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajustar la imagen para que llene el contenedor sin distorsionar */
  }
  .image-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 70%; /* Establece la relación de aspecto 1:1 */
    overflow: hidden;
  }
  
  .image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajustar la imagen para que llene el contenedor sin distorsionar */
  }
  
  .image-container {
    position: relative;
    height: 150px;
    max-height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
  
  .image-container img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
  
  .b-icon-dash path {
    stroke-width: 3px; 
  }
  </style>
