<template>
    <div class="image_wrapper">
        <img
        src="../../assets/empleado-dentalia.png"
        alt=""
      />
            <div class="overlay overlay_2">
                <div class="row text-center" >
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <p class="text-overlay" style="font-size: 2vh;">CUIDA DE TU SALUD DENTAL</p>
                        <h2>
                          Tenemos planes especiales<br />
                          para ti
                        </h2>
                        <b-button class="mt-4" variant="outline-light" style="border-radius: 20px; border-width: 3px; font-weight: 600;" @click="verPlanes()">Ver planes disponibles</b-button>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6"></div>
                </div>
                   
                </div>
            </div>
 
</template>

<script>
    export default {
      name: "ImgInicio",
      components: {
      },
      data: () => ({
    }),
    methods: {
        verPlanes(){
            this.$router.push({ name: "Planes" });
        }
    }
    };
    </script>
    
    <style scoped>


.image_wrapper {
  position: relative;

}

img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    object-position: center;

}

.overlay {
  position: absolute; 
  background: rgba(0, 0, 0, 0.325);
  padding-top: 5%;
  justify-content: left;
  color: white;
}


.overlay_2 {
  inset: 0;
}

@media (min-width:1499px){
    .overlay {
  position: absolute; 
  background: rgba(0, 0, 0, 0.325);
  align-items: center;
  justify-content: center;
  color: white;
}
.text-overlay{
    margin-top: 5%;
}
}

@media (max-width:900px){
    .overlay {
  position: absolute; 
  background: rgba(0, 0, 0, 0.325);
  align-items: center;
  justify-content: center;
  color: white
}

.text-overlay{
    margin-top: 20%;
}

}

    </style>
    
