<template>
    <div>
        <BarraNavegacion :membresias="membresias" :esPlan="esPlan"/>
        
        <div class="p-0 contenedor-pacientes">
            <b-row class="m-0 p-0" style="">
                <b-col class="col m-0 p-0" style="">
                    <div v-if="windowWidth > 768" class="d-flex flex-row p-0 pl-4 wizardCompleto" style="min-height: 55px; border-bottom: .01vh solid #EFEFEF;" >
                        <div class="pr-4" v-for="(option, index) in wizard" v-bind:key="index">
                            <div class="d-flex justify-content-center align-items-center" :class="{'lineaActiva': index + 1 == 1}" style="min-height: 55px;">
                                <div class="d-flex justify-content-between" style="width: 140px;">
                                    <div class="d-flex justify-content-center align-items-center" :class="index + 1 != 1 ? 'circuloWizardInactivo' : 'circuloWizardActivo'">{{ index+1 }}</div>
                                    <p class="m-0 p-0" :class="index + 1 != 1 ? 'txtWizardInactivo' : 'txtWizardActivo'">{{option}}</p>
                                    <em class="chevron_right mt-2 "></em>
                                </div>
                            </div>
            
                        </div>
                    </div>
                    
                    <div v-if="windowWidth <= 768">
                       <b-row class="p-0 m-0" style="border-bottom: 1px solid #EFEFEF;">
                        <b-col cols="6" style="border-right: 1px solid #EFEFEF;" >
                            <div id="info-wizard" class="d-flex justify-content-start align-items-center lineaActiva"  style="min-height: 55px; max-height: 100px;  width: 60%;">
                                <div class="d-flex justify-content-between" style="width: 140px;">
                                    <div class="d-flex justify-content-center align-items-center circuloWizardActivo mr-2" :class="index + 1 != 1 ? 'circuloWizardInactivo' : 'circuloWizardActivo'">
                                        <p class="p-0 m-0" style="font-size: .8em; color: white">1/3</p>
                                    </div>
                                    <p class="m-0 p-0 txtWizardActivo" >{{wizard[0]}} </p>
                                    <em class="chevron_down "></em>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <div class="d-flex justify-content-center align-items-center" style="min-height: 50px; max-height: 100px;">
                                <em class="carrito mt-1 mr-2"></em>
                                <p v-if="precioTotal != undefined" class="m-0 txtWizardActivo" style="font-size: 1em; font-weight: 500;">{{ precioTotal | moneda }}</p>
                            </div>
                        </b-col>
                       </b-row>
                            
                       <b-popover target="info-wizard" variant="light" triggers="hover blur" @show="onPopoverShow" @hidden="onPopoverHide">
                            <div class="d-flex justify-content-start align-items-center mt-2" v-for="(option, index) in wizard" v-bind:key="index" style="min-height: 30px;">
                                <div class="d-flex justify-content-center align-items-center " :class="index + 1 != 1 ? 'circuloWizardInactivo' : 'circuloWizardActivo'" style="height: 20px !important; width: 20px !important;">{{ index+1 }}</div>
                                <p class="m-0 p-0 ml-2" :class="index + 1 != 1 ? 'txtWizardInactivo' : 'txtWizardActivo'">{{option}} </p>      
                            </div>
                       </b-popover>
                       

                       <div class="padding-formulario">
                        <p style="font-weight: 600; color: #0069A9; font-size: .9em;">Resumen del carrito</p>
                        <p style="font-size: .8em;">Por favor seleccione cada plan para configurar los pacientes correspondientes.</p>
                        <b-form-select size="sm" v-model="planSeleccionado" :options="optionsWithIndex" value-field="index"
                        text-field="name">
                        </b-form-select>
                     
                        <div class="mt-3">
                            <p style="font-weight: 500; font-size: .8em;">Pacientes del plan:</p>
                            <div v-for="(paciente, index) in carrito[planSeleccionado].pacientes" v-bind:key="index" style="font-size: .8em;">
                                <ul>
                                    <li v-if="paciente.nombrePaciente != null && paciente.apellidoP != null && paciente.apellidoM != null && (paciente.nombrePaciente != '' || paciente.apellidoP != '' || paciente.apellidoM != '')">
                                        <p>{{ paciente.nombrePaciente + ' ' + paciente.apellidoP + ' ' + paciente.apellidoM }}</p>
                                    </li>
                                    <li v-else style="color: #BBBBBB;">
                                        <p style="color: #BBBBBB;">Nuevo paciente</p>
                                    </li>
                                </ul>
                               
                            </div>
                        </div>
                       </div>

                       

                    </div>
                    <div class="padding-formulario" v-if="carrito != null">
                        <p style="font-weight: 600; color: #0069A9; font-size: .9em;">Configuración de pacientes</p>
                        <FormularioPaciente :planSeleccionado="planSeleccionado" @inputChange="actualizarEstadoFormulario" :membresias="membresias"/>
                    </div>
                    
                    <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 70vh;">
                        <p class="text-center w-75">No se encontró información relacionada a este plan, por favor vuelva a intentarlo</p>
                    </div>


                    
                </b-col>
                <b-col xl="4" lg="4" md="12" class="m-0 p-0" style="border-left: 1px solid #EFEFEF; height: auto;">
                    
                    <div class="d-flex align-items-center" :class="{'grayLine': windowWidth > 768}">
                        <div class="d-flex ml-4" v-if="windowWidth > 768">
                            <em class="carrito mt-1 mr-2"></em>
                            <p v-if="precioTotal != undefined" class="m-0" style="font-size: 1.3em; font-weight: 500;">{{ precioTotal | moneda }}</p>
                        </div>
                    </div>
                    <ListadoPlanes v-if="carrito != null" :membresias="membresias" :carritoPlanes="carrito" :formularioValido="formularioValido" @clicBoton = "clicBoton" @seleccionarPlan = "seleccionarPlan" :windowWidth=" windowWidth"/>
                </b-col>    
            </b-row>        
        </div>
       
    </div>
  </template>
  
  <script>
  import FormularioPaciente from "../../views/portal_membresia_version/FormularioConfiguracionPacientes.vue";
  import ListadoPlanes from "./ListadoPlanes.vue"
  import BarraNavegacion from "../nuevo_portal/BarraNavegacion.vue"
  
  
  export default {
    name: "CarritoPaciente",
    components: {
        FormularioPaciente,
        ListadoPlanes,
        BarraNavegacion
    },
    props: {
     
    },
    data() {
      return {
        wizard: [
            "Configura",
            "Paga",
            "Disfruta"
        ],
        valorDesdeResumenCompraLocal: null,
        formularioValido: false,
        carrito: [],
        precioTotal: 0,
        planSeleccionado: 0,
        membresias: false,
        carritoOriginal: null,
      
        windowWidth: window.innerWidth,
        segundaOpcionAbierto: false,
        emitirContinuarProp: false,
        esPlan: false
      };
    },
    computed: {
    optionsWithIndex() {
      return this.carrito.map((item, index) => {
        return {
          index: index,
          name: item.nombrePlan
        };
      });
    },
    selectedItem() {
      return this.items[this.selectedIndex];
    }
  },
    created() {
        this.carritoOriginal = this.$session.get("carrito");
        if(this.carritoOriginal != null && this.carritoOriginal.length > 0){
            if(this.carritoOriginal[0].membresias && this.carritoOriginal[0].membresias == true){ 
                this.membresias = true;
                
                
            
                this.carritoOriginal.forEach(plan => {
                    plan.planDesplegado = true;
                    const existingCount = this.carritoOriginal.filter(item => item.idPlan === plan.idPlan).length;

                    if (existingCount < plan.cantidad) {
                            for (let index = 0; index < plan.cantidad; index++) {
                                this.carrito.push(plan)
                            }
                        
                    }else{
                        this.carrito = this.carritoOriginal;
                    }
                });
            /* } */
                this.crearCarritoMembresia();

                
            }else{
                this.carrito = this.carritoOriginal;
            }
        }else{
            this.carrito = null;
           
        }

        this.esPlan = this.$session.get('esPlan')
        this.carrito.forEach(plan => {
                this.precioTotal += plan.precio;
            });

    },
    mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },

    methods: {
        emitirContinuar() {
        this.emitirContinuarProp = !this.emitirContinuarProp; 
        },
        onPopoverShow() {
            this.segundaOpcionAbierto = true;
            },
        onPopoverHide() {
            this.segundaOpcionAbierto = false;
        },

        crearCarritoMembresia(){
            this.crearPacientes();
            this.asignarImagenes();

            this.$session.set('carrito', this.carrito);
        },
        
        crearPacientes(){
            this.carrito.map((plan) => {
                 plan.pacientes = [...Array(plan.numeroMaximo).keys()].map(() => ({}));
                 return plan;
             });

            
        },

        asignarImagenes(){
            const context = require.context(
                 "@/assets/imagenes_planes",
                 false,
                 /\.(jpg|jpeg|png|gif|svg)$/
             );
             const imagenes = context.keys().map((imagenPath) => {
                 const nombreImagen = imagenPath.match(
                     /\/([^/]+)\.(jpg|jpeg|png|gif|svg)$/
                 )[1];
                 return {
                 src: context(imagenPath),
                 alt: nombreImagen,
                 };
             });

             this.carrito.forEach((plan, index) => {
               /* let index  = Math.floor(Math.random() * 8); */
               let ruta = imagenes[index].src
               plan.imagenPlan = ruta;
             });
        },    
        seleccionarPlan(planSeleccionado){
            this.planSeleccionado = planSeleccionado;
        },
        actualizarEstadoFormulario(resultadoValidacion, carrito) {
            this.formularioValido = resultadoValidacion;
            this.carrito = carrito;

        },

        clicBoton(){
            
            this.$emit("clicBoton");
            
        },
        actualizarValor(nuevoValor) {
            this.valorDesdeResumenCompraLocal = nuevoValor;
        },
        traerPacientes(traerPacientes){
            this.traerPacientesLocal = traerPacientes;
        }
    },
  };
  </script>
  
  <style scoped>
    .grayLine{
        min-height: 55px;
        border-bottom: .01vh solid #EFEFEF;
    }
    .padding-formulario{
        padding: 4% 2% 0% 2%;
    }
    .contenedor-pacientes{
        background-color: white;
        border: 1px solid #EFEFEF;
        margin: 2% 5% 16% 5%;
        border-radius: 20px;
    }

    .mostrar{
            display: none;
        }

        .wizardCompleto{
            display: block;
        }
    @media (max-width: 426px) {
        .padding-formulario{
            padding: 4% 4% 10% 4%;
        }
    }

    @media (max-width: 768px) {
        

        .contenedor-pacientes{
            background-color:white;
            border: 1px solid #EFEFEF;
            margin: 2% 5% 2% 5%;
            border-radius: 20px;
            height: auto;
        }
    }

    .carrito {
        content: url("../../assets/icons/carrito_outline.svg");
    }

    .circuloWizardInactivo{
        color: white;
        background-color: #C2C2C2;
        border-radius: 50%;
        width: 24px;
        height: auto;
    }

    .circuloWizardActivo{
        color: white;
        background-color: #4B94C2;
        border-radius: 50%;
        width: 24px;
        height: auto;
    }

    .txtWizardInactivo{
        color: #C2C2C2;
        font-weight: 500;
    }

    .txtWizardActivo{
        color: #363F55;
        font-weight: 500;
    }

    .lineaActiva{
        border-bottom: 2px solid #4B94C2;
    }

    
  </style>
