import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import LoginToken from '../components/LoginToken.vue'
//import Planes from '../views/Planes.vue'
//import Carrito from '../views/Carrito.vue'
// import CarritoPaciente from '../views/CarritoPaciente.vue' 
import CarritoPago from '../views/CarritoPago.vue'
import CarritoFin from '../components/CarritoFin.vue'
import PagoError from '../views/PagoError.vue'
import PagoGracias from '../views/PagoGracias.vue'
/* import Facturacion from '../views/Facturacion' */
import tema from '../views/tema'
import LoginTokenTema from "../components/LoginTokenTema";

import ForgottenPassword from "@/components/control-acceso/ForgottenPassword";
import ResetPassword from "@/components/control-acceso/ResetPassword";
import VerificationCode from "@/components/control-acceso/VerificationCode";



/* nuevo portal */
import Planes from '@/views/nuevo_portal/Planes.vue'
import CarritoPaciente from '@/views/nuevo_portal/ConfiguracionPacientes.vue'
import Carrito from '@/views/nuevo_portal/Carrito.vue'
import PlanesInformacion from "@/views/nuevo_portal/PlanesInformacion";
/* import FinCompra from "@/views/nuevo_portal/FinCompra" */
import Facturacion from '../views/nuevo_portal/Facturacion.vue'
import RespuestaError from "@/views/RespuestaError.vue";


/* nuevo portal membresías */
import ConfiguracionMembresia from "@/views/portal_membresia_version/ConfiguracionPacientes.vue"
import FinCompraMembresia from "@/views/portal_membresia_version/FinCompra.vue"
import MembresiasDentalia from "@/views/portal_membresia_version/MembresiasDentalia";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/tema',
    name: 'tema',
    component: tema
  },
  {
    path: '/token/:token',
    name: 'LoginToken',
    component: LoginToken
  },
  {
    path: '/token/tema/:token',
    name: 'LoginToken',
    component: LoginTokenTema
  },
  {
    path: '/planes',
    name: 'Planes',
    component: Planes
  },
  {
    path: '/inicio',
    name: 'Inicio',
    component: PlanesInformacion
  },
  {
    path: '/carrito',
    name: 'Carrito',
    component: Carrito
  },
  {
    path: '/carrito/paciente',
    name: 'CarritoPaciente',
    component: CarritoPaciente
  },
  {
    path: '/carrito/pago',
    name: 'CarritoPago',
    component: CarritoPago
  },
  {
    path: '/carrito/final',
    name: 'CarritoFin',
    component: CarritoFin
  },
  {
    path: '/error/:tipoError',
    name: 'PagoError',
    component: PagoError
  },
  {
    path: '/gracias',
    name: 'PagoGracias',
    component: PagoGracias
  },
  {
    path: '/facturacion',
    name: 'Facturacion',
    component: Facturacion
  },
  {
    path: '/forgottenPassword',
    name: 'ForgottenPassword',
    component: ForgottenPassword
  },
  {
    path: '/resetPassword/:codigo',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/verificationCode',
    name: 'VerificationCode',
    component: VerificationCode
  },
  {
    path: '/error2',
    name: 'Error',
    component: RespuestaError
  },
  {
    path: '/carrito/membresiasDentalia/:token(.*)*',
    name: 'MembresiasDentalia',
    component: MembresiasDentalia
  },
  {
    path: '/carrito/configuracionPacientes',
    name: 'ConfiguracionMembresia',
    component: ConfiguracionMembresia
  },
 /*  {
    path: '/finalizacionCompra',
    name: 'FinCompra',
    component: FinCompraMembresia
  },  */
  {
    path: '/finCompra/:token(.*)*',
    name: 'FinCompra',
    component: FinCompraMembresia
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
