<template>
<div>
  <div>
    <Header></Header>
  </div>

  <div class="espaciado" fluid="sm">
    <b-card-group>
      <b-card
          header-text-variant="white"
          header="Código de verificación"
          header-tag="header"
      >

        <p>
          Detectamos algunos cambios durante su inicio de sesión, para brindarle una mayor seguridad,
          le hemos enviado un código de verificación a su dirección de correo electrónico.
          Por favor, introduzca el código a continuación.
        </p>
        <b-form @submit="onSubmit">
          <b-form-group
              label="Código"
              label-for="input-code"
          >

            <b-form-input
                id="input-code"
                v-model="codigo"
                type="text"
                aria-describedby="input-1-live-feedback"
                minlength="6"
                maxlength="6"
                required
            ></b-form-input>

            <div>
              <span>{{totalTime}}s</span><span style="font-weight: 300;"> para restablecer el código</span>
            </div>

          </b-form-group>
          <b-button v-if="blnReenviar" type="submit" variant="outline-primary">
            <span v-show="!mostrarSpinnerContinuar">Verifica</span>

            <b-spinner v-show="mostrarSpinnerContinuar" small></b-spinner>
            <span v-show="mostrarSpinnerContinuar"> Cargando...</span>
          </b-button>
          <b-button v-on:click="reenviarCodigoContrasenia" v-else variant="outline-secondary">
            <span v-show="!mostrarSpinnerContinuar">Reenviar</span>
            <b-spinner v-show="mostrarSpinnerContinuar" small></b-spinner>
            <span v-show="mostrarSpinnerContinuar"> Cargando...</span>
          </b-button>
          <b-button v-on:click="onReset" variant="outline-secondary">Atrás</b-button>

        </b-form>
      </b-card>
    </b-card-group>
  </div>

  <div>
    <footer id="footerLogin">
    </footer>
  </div>
</div>
</template>

<script>
import Header from "@/components/control-acceso/Header";
export default {
  name: "VerificationCode",
  components: {Header},
  data() {
    return {
      codigo: null,
      idUsuario: null,
      tipoSistema: null,

      tiempoReenvio: 2,
      mostrarTiempoReenvio: true,
      blnReenviar: true,
      totalTime: null,
      mostrarSpinnerContinuar: false
    }
  },
  created() {
    this.idUsuario = this.$localStorageService.obtenerIdUsuarioAutenticado();
   this.totalTime = 7*60;
    this.updateClock()
    this.tipoSistema = Number(this.$idSistema);
  },
  methods: {
    onSubmit(event){
      event.preventDefault()
      this.guardarCodigoContrasenia();
    },
    guardarCodigoContrasenia() {
      this.mostrarSpinnerContinuar = true;
      this.$http
          .post(this.$apiControlAcceso +
            "guardar/codigoContrasenia", {
              codigo: this.codigo,
              idUsuario: this.idUsuario,
              tiempo: 0
            }
          )
          .then((respuesta) => {
            this.mostrarSpinnerContinuar = false;
            if (respuesta.data.data.codigo === 200) {
              if(!respuesta.data.data.error){
                this.tiempoReenvio = 1;
                this.mostrarTiempoReenvio = false;
                this.$swal.fire({
                  icon: "success",
                  title: respuesta.data.data.mensaje,
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push({ name: "Login" })
                  }
                })
              } else {
                this.$swal.fire({
                  icon: "warning",
                  title: respuesta.data.data.mensaje,
                });
              }
            } else {
              this.$swal.fire({
                icon: "error",
                title: respuesta.data.data.mensaje,
              });
            }
          })
    },
    onReset(event) {
      event.preventDefault()
      this.$router.push({ name: "Login" })
    },
    reenviarCodigoContrasenia() {
      this.mostrarSpinnerContinuar = true;
      const parametros = {idUsuario: this.idUsuario, idSistema: this.tipoSistema}
      this.$http
          .post(this.$apiControlAcceso +
              "reenviarContrasenia", parametros
          )
          .then((respuesta) => {
            this.mostrarSpinnerContinuar = false;
            if (respuesta.data.data.codigo === 200) {
              if(!respuesta.data.data.error){
                this.$swal.fire({
                  icon: "success",
                  title: respuesta.data.data.mensaje,
                  allowOutsideClick: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.push({ name: "Login" })
                  }
                })
              } else {
                this.$swal.fire({
                  icon: "warning",
                  title: respuesta.data.data.mensaje,
                });
              }
            } else {
              this.$swal.fire({
                icon: "error",
                title: respuesta.data.data.mensaje,
              });
            }
          })
    },
    updateClock() {
      if(this.totalTime === 0){
        console.log('Final');
        this.blnReenviar = false;
      }else{
        this.totalTime -= 1;
        setTimeout(() => {this.updateClock(); }, 1000);
      }
    }
  },
  watch: {
    tiempoReenvio: {
      handler(value){
        if(value > 0) {
          setTimeout(() => {
            this.tiempoReenvio--;
          }, 1000);
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.espaciado {
  margin: 6%;
}

.card-header:first-child {
  background-color: #0e6ba9 !important;
}
</style>