<template>
  <div>
    <div>
      <table class="table">
        <tr>
          <td>Total</td>
          <td>{{ total | moneda }}</td>
        </tr>
      </table>
      <hr/>
      <img class="mb-4" src="../assets/visa.png" alt="" v-if="pago"/>
      <img class="mb-4" src="../assets/verificado.gif" alt=""/>
    </div>
    <div id="nota">
      <div v-if="pago">
        <p>
          <strong>Nota: </strong> Solo puede facturar durante 30 días
          después de la fecha de compra.
        </p>
        <hr/>
        <p>
          Aceptamos las principales tarjetas de crédito y débito en México.
        </p>
      </div>

      <div v-else>
        <p>
          ¿Pagos diferidos? Haz clic en Continuar para conocer las opciones
          de pago.
        </p>
      </div>

      <p class="mt-4">¿Necesitas ayuda? ¡Llámanos! 01.800.003.3682</p>
      <p>
        Precios expresados en pesos mexicanos (MXN). Incluyen el 16% de IVA.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResumenCompra",
  props: {
    pago: {
      type: Boolean,
      require: true,
    },
  },
  data: () => ({
    total: 0,
  }),
  created() {
    this.calcular()
    this.$root.$on('carritonav', () => {
      this.calcular();
    })
  },
  methods: {
    calcular() {
      const carrito = this.$session.get('carrito');
      if (carrito && carrito.length > 0) {
        this.total = this.$session.get('carrito').map(plan => plan.cantidad * plan.precio).reduce((a, b) => a + b)
      } else {
        this.total = 0;
      }
    }
  }
};
</script>

<style>
</style>
