<template>
    <div>
      <p class="text-subtitulos">Resumen de compra</p>
      <p>Navegue entre los planes para ingresar a los pacientes de cada uno</p>
      <b-table
        :items="carritoPlanes"
        :fields="titulos"
       class="tabla-resumen"
        tbody-class="bg-white"
        hover
        responsive
        selectable
        :select-mode="'single'"
        @row-selected="onRowSelected"
        sticky-header
      >
      <template #cell(No.)="data">
        {{ data.index + 1 }}
      </template>
     
      </b-table>
 
    </div>
  </template>
  
  <script>
  export default {
    name: "ResumenCompra",
    props: {
      pago: {
        type: Boolean,
        require: true,
      },
      valorDesdeResumenCompra: Number,
    },
    data: () => ({
      contactoPacienteGuardado: false,
      modalContacto: false,
      total: 0,
      carritoPlanes: null,
      titulos: [
      'No.',
        { key: "Seleccion", label: "" }, // Agrega una columna vacía para la selección
        { key: "nombrePlan", label: "Nombre plan" },
        { key: "tipoValidacion", label: "Tipo plan" },
        { key: "numeroMaximo", label: "Número pacientes" },
      ],
      selected: null,
    }),
    mounted(){

    },
    created() {
      this.carritoPlanes = this.$session.get("carrito");
      this.calcular();
      this.$root.$on("carritonav", () => {
        this.calcular();
      });
  
      

    },
    methods: {
      onRowSelected(items) {
        
        this.selected = items;
        
        const indice = this.carritoPlanes.findIndex(
          (plan) => plan === this.selected[0]
        );

        this.$emit("actualizarValor", indice);
      },
      traerPacientes() {
        this.modalContacto = true;
        this.$emit("traerPacientes", true);
      },
  
      guardarCarritoTemporal() {
        let lista = this.$session.get("carrito");
        const planes = lista.map((plan) => {
          plan.pacientes = [...Array(plan.numeroMaximo).keys()].map(() => ({}));
          return plan;
        });
        this.$session.set(
          "carrito",
          planes
            .map((plan) => {
              return [...Array(plan.cantidad).keys()].map(() => ({
                ...plan,
                cantidad: 1,
              }));
            })
            .reduce((a, b) => a.concat(b))
        );
        this.$root.$emit("carritonav");
        this.$router.push({ name: "CarritoPaciente" });
      },
      calcular() {
        const carrito = this.$session.get("carrito");
        if (carrito && carrito.length > 0) {
          this.total = this.$session
            .get("carrito")
            .map((plan) => plan.cantidad * plan.precio)
            .reduce((a, b) => a + b);
        } else {
          this.total = 0;
        }
      },
    },
  };
  </script>
  
  <style>
    .tabla-resumen {
  max-height: 500px; 
  overflow-y: auto; 
}

  </style>
  