<template>
<div></div>
</template>
<script>
export default {
  name: "validacionSession",
  data() {
    return {

    }
  },
  created() {
    this.validarSesionActiva();
  },
  methods: {
    validarSesionActiva(){
      setInterval( ()=>{ this.obtenerVigenciaToken();}, 300000); // 5 minutos a 300000
    },
    obtenerVigenciaToken() {
      let parametros = {
        idControlAcceso: this.$session.get("idControlAcceso")
      };
      if(parametros.idControlAcceso !== null) {
        this.$http
            .post(this.$apiControlAcceso +
                "validacion/token",parametros
            )
            .then((respuesta) => {
              console.log(respuesta.data);
              if (respuesta.data.data) {
                console.log("")
              }else{
                localStorage.clear();
                this.$session.destroy();
                this.$router.push("/");
              }
            })  
      }else{
        console.log("validando...");
      }
    }
  }
}
</script>
<style>

</style>