<template>
  <main>
    <BarraNavegacion/>
    <BreadCrumbs v-if="configuracion" :step="1" class="mt-5"></BreadCrumbs>
    <div class="mt-5 ml-5 mr-5">
      <b-row>
        <b-col class="col-sm-12 col-md-12 col-lg-8">
          <div class="padding-ajustable" style="background-color: white; border-radius: 20px; border: 1px solid #D6E7F3;">
            <template >
              <h1 class="left margin-ajustable">
                <slot name="header"/>
              </h1>
            </template>
            <b-card-body style="padding: 20px 10px 0px 10px !important;">
              <slot name="content"/>
            </b-card-body>
          </div>
        </b-col>
        <b-col class="col-sm-12 col-md-12 col-lg-4">
          <div class="">
            <template >
              <h1 class="left">
                <slot name="headerDetalle"/>
              </h1>
            </template>
              <slot name="detallePlan"/>
          </div>
        </b-col>
      </b-row>
    </div>
  </main>
</template>

<script>
import BarraNavegacion from "../../views/nuevo_portal/BarraNavegacion";
import BreadCrumbs from "../../components/BreadCrumbs";

export default {
 props: {
    stepBread: null,
    configuracion: Boolean,
  },
  name: "PortalVista",
  components: { BarraNavegacion, BreadCrumbs},
  data: () => ({
    loading: false,
    
  }),

}
</script>

<style scoped>
  .padding-ajustable{
    padding: 3%;
  }

  @media (max-width: 767px) {
    .padding-ajustable{
      padding: 5% 1% 1% 1%;
    }

    .margin-ajustable{
      margin-left: 4%;
    }
  }
</style>
